import React from 'react'

const Loader = () => {
    return (
        <div className="loader_block">
            <div className="loader"><span></span></div>
        </div>
    )
}

export default Loader;