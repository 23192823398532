import { create } from 'apisauce'
import { v4 as uuidv4 } from 'uuid';
const basepath = "https://data.anywhere.hr/api/v1/";
// const devpath = (window.location.hostname === 'data.anywhere.hr') ? "https://data.anywhere.hr/api/v1/" : 'http://localhost:8000/api/v1/';
const AuthURL = basepath + 'auth';
const GruntovnicaURL = basepath + 'gruntovnica';
const KatastarPLURL = basepath + 'katastar_posjedovni_list';
const PolozeniUgovoriURL = basepath + 'polozeni_ugovori';
const AdresarURL = basepath + 'adresar';
const ImenikURL = basepath + 'imenik';
const RegistracijeURL = basepath + 'registracije';
const SudregObjaveURL = basepath + 'sudreg_objave';
const SudregApiURL = basepath + 'sudreg_api';
const SudObjaveURL = basepath + 'sud_objave';
const PoslovniImenikURL = basepath + 'poslovni_imenik';
const FacebookURL = basepath + 'facebook';
const ObrtniRegistarURL = basepath + 'obrtni_registar';
const DozvoleURL = basepath + 'dozvole';
const StatusURL = basepath + 'status';

const apiAuthInstance = create({
  baseURL: AuthURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiGruntovnicaInstance = create({
  baseURL: GruntovnicaURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiKatastarPLInstance = create({
  baseURL: KatastarPLURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
const apiPolozeniUgovoriInstance = create({
  baseURL: PolozeniUgovoriURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiAdresarInstance = create({
  baseURL: AdresarURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiImenikInstance = create({
  baseURL: ImenikURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiRegistracijeInstance = create({
  baseURL: RegistracijeURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiSudregObjaveInstance = create({
  baseURL: SudregObjaveURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiSudregApiInstance = create({
  baseURL: SudregApiURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiSudObjaveInstance = create({
  baseURL: SudObjaveURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiDozvoleInstance = create({
  baseURL: DozvoleURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiStatusInstance = create({
  baseURL: StatusURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiPoslovniImenikInstance = create({
  baseURL: PoslovniImenikURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiFacebookInstance = create({
  baseURL: FacebookURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiObrtniRegistarInstance = create({
  baseURL: ObrtniRegistarURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});


export default class Api {
  static token = undefined;

  static saveToken = (token) => {
    console.log('Saving token to Api');
    apiAuthInstance.setHeader('Authorization', `Bearer ${token}`);
    apiGruntovnicaInstance.setHeader('Authorization', `Bearer ${token}`);
    apiKatastarPLInstance.setHeader('Authorization', `Bearer ${token}`);
    apiPolozeniUgovoriInstance.setHeader('Authorization', `Bearer ${token}`);
    apiAdresarInstance.setHeader('Authorization', `Bearer ${token}`);
    apiImenikInstance.setHeader('Authorization', `Bearer ${token}`);
    apiRegistracijeInstance.setHeader('Authorization', `Bearer ${token}`);
    apiSudregObjaveInstance.setHeader('Authorization', `Bearer ${token}`);
    apiSudregApiInstance.setHeader('Authorization', `Bearer ${token}`);
    apiSudObjaveInstance.setHeader('Authorization', `Bearer ${token}`);
    apiDozvoleInstance.setHeader('Authorization', `Bearer ${token}`);
    apiStatusInstance.setHeader('Authorization', `Bearer ${token}`);
    apiPoslovniImenikInstance.setHeader('Authorization', `Bearer ${token}`);
    apiFacebookInstance.setHeader('Authorization', `Bearer ${token}`);
    apiObrtniRegistarInstance.setHeader('Authorization', `Bearer ${token}`);
    this.token=token;
  };

  static setLogout = (logout) => {
    console.log('Adding logout to Api');
    this.logout = logout;
  }

  static processError = (data, module) => {
    if(data && data.data && data.data.message === 'TokenExpiredError: jwt expired'){
      alert('Your session has expired. Please log back in.');
      this.logout();
      return {error: true, message: 'Your session has expired. Please log back in.'};
    }
    else if (data && data.data && data.data.message === 'ERROR' && data.data.data === 'ACCESS_DENIED'){
      return {error: true, message: 'Nemate ovlasti pristupiti ovoj ruti. Molimo da se javite Anywhere podršci (support@anywhere.hr).'};
    }
    else if (data && data.data && data.data.message === 'ERROR' && data.data.data === 'LIMIT_REACHED'){
      return {error: true, message: 'Dostigli ste limit prikaza dokumenata. I dalje možete vidjeti dokumente koje ste već otvarali. Za povećanje limita javite se Anywhere podršci (support@anywhere.hr).'};
    }
    else if (data && data.data && data.data.message && data.data.message.indexOf("request.body.text should NOT be shorter than 3 characters, request.body should have required property 'zk_ulozak', request.body should match some schema in anyOf") > -1){
      return {error: true, message: 'Molimo unesite barem 3 znaka ili zemljišnoknjižni uložak.'}
    }
    else if (data && data.data && data.data.message && data.data.message.indexOf("request.body.text should NOT be shorter than 3 characters, request.body should have required property 'posjedovni_list', request.body should match some schema in anyOf") > -1){
      return {error: true, message: 'Molimo unesite barem 3 znaka ili posjedovni list.'}
    }
    else if (data && data.data && data.data.message && data.data.message.indexOf('request.body.text should NOT be shorter than 3 characters') > -1){
      return {error: true, message: 'Molimo unesite barem 3 znaka.'}
    }
    else if (data && data.data && data.data.message && data.data.message === 'ERROR'){
      return {error: true, message: data.data.data}
    }
    else {
      return {error: true, message: 'Unable to communicate to "' + module + '" Backend'}
    }
  }

  static login = async (data) => {
    data.authType = 'password';

    var clientId = localStorage.getItem('clientId');
    if (clientId === null){
      clientId = uuidv4();
      localStorage.setItem('clientId', clientId);
    }
    apiAuthInstance.setHeader('clientId', clientId);
    const res = await apiAuthInstance.post('/login', data);
    return res;
  };

  static register = async (data) => {
    data.authType = 'password';
    const res = await apiAuthInstance.post('/register', data);
    return res;
  };

  static activate = async (email, activationToken) => {
    const res = await apiAuthInstance.get('/activate', { email: email, activationToken: activationToken });
    return res;
  }

  static reset = async (data) => {
    const res = await apiAuthInstance.post('/reset', data);
    return res;
  };

  static checkProtected = async () => {
    const res = await apiAuthInstance.get('/check_protected');
    return res;
  }

  static getUser = async () => {
    const res = await apiAuthInstance.get('/user');
    return res;
  }

  static addUserRole = async (data) => {
    const res = await apiAuthInstance.post('/addUserRole', data);
    return res;
  }

  static removeUserRole = async (data) => {
    const res = await apiAuthInstance.post('/removeUserRole', data);
    return res;
  }

  static getLogs = async (data) => {
    const res = await apiAuthInstance.post('/getLogs', data);
    return res;
  }

  static Gruntovnica = {
    checkProtected: async () => {
      const res = await apiGruntovnicaInstance.get('/check_protected');
      return res.ok ? res.data : this.processError(res, 'Gruntovnica');
    },
    list: async (data) => {
      const res = await apiGruntovnicaInstance.post('/list', data);
      return res.ok ? res.data : this.processError(res, 'Gruntovnica');
    },
    search:  async (data) => {
      const res = await apiGruntovnicaInstance.post('/search', data);
      return res.ok ? res.data : this.processError(res, 'Gruntovnica');
    },
    html: async (uuid) => {
      const res = await apiGruntovnicaInstance.get('/html', {uuid: uuid});
      return res.ok ? res.data : this.processError(res, 'Gruntovnica');
    }
  }

  static KatastarPL = {
    checkProtected: async () => {
      const res = await apiKatastarPLInstance.get('/check_protected');
      return res.ok ? res.data : this.processError(res, 'KatastarPL');
    },
    list: async (data) => {
      const res = await apiKatastarPLInstance.post('/list', data);
      return res.ok ? res.data : this.processError(res, 'KatastarPL');
    },
    search:  async (data) => {
      const res = await apiKatastarPLInstance.post('/search', data);
      return res.ok ? res.data : this.processError(res, 'KatastarPL');
    },
    html: async (uuid) => {
      const res = await apiKatastarPLInstance.get('/html', {uuid: uuid});
      return res.ok ? res.data : this.processError(res, 'KatastarPL');
    }
  }
  static PolozeniUgovori = {
    checkProtected: async () => {
      const res = await apiPolozeniUgovoriInstance.get('/check_protected');
      return res.ok ? res.data : this.processError(res, 'PolozeniUgovori');
    },
    list: async (data) => {
      const res = await apiPolozeniUgovoriInstance.post('/list', data);
      return res.ok ? res.data : this.processError(res, 'PolozeniUgovori');
    },
    search:  async (data) => {
      const res = await apiPolozeniUgovoriInstance.post('/search', data);
      return res.ok ? res.data : this.processError(res, 'PolozeniUgovori');
    },
    html: async (uuid) => {
      const res = await apiPolozeniUgovoriInstance.get('/html', {uuid: uuid});
      return res.ok ? res.data : this.processError(res, 'PolozeniUgovori');
    }
  }

  static Adresar = {
    checkProtected: async () => {
      const res = await apiAdresarInstance.get('/check_protected');
      return res.ok ? res.data : this.processError(res, 'Adresar');
    },
    list: async (data) => {
      const res = await apiAdresarInstance.post('/list', data);
      return res.ok ? res.data : this.processError(res, 'Adresar');    },
    search:  async (data) => {
      var res = await apiAdresarInstance.post('/search', data);
      data.apiKey = Api.token;
      data.text = encodeURIComponent(data.text);
      res.data.url = (new URL(AdresarURL + '/geoJSON?' + new URLSearchParams(data)))
      return res.ok ? res.data : this.processError(res, 'Adresar');
    },
    searchUrl: (data) => {
      data.apiKey = Api.token;
      return new URL(AdresarURL + '/geoJSON?' + new URLSearchParams(data))
    },
  }

  static Imenik = {
    checkProtected: async () => {
      const res = await apiImenikInstance.get('/check_protected');
      return res.ok ? res.data : this.processError(res, 'Imenik');
    },
    search:  async (data) => {
      var res = await apiImenikInstance.post('/search', data);
      return res.ok ? res.data : this.processError(res, 'Imenik');
    },
  }

  static Registracije = {
    checkProtected: async () => {
      const res = await apiRegistracijeInstance.get('/check_protected');
      return res;
    },
    search:  async (data) => {
      var res = await apiRegistracijeInstance.post('/search', data);
      return res;
    },
  }

  static RegistracijeRaw = {

    checkProtected: async () => {
      const res = await apiRegistracijeInstance.get('/check_protected');
      return res;
    },
    search:  async (data) => {
      data.table='RegistracijeRaw';
      var res = await apiRegistracijeInstance.post('/search', data);
      return res;
    },
  }

  static SudregObjave = {
    checkProtected: async () => {
      const res = await apiSudregObjaveInstance.get('/check_protected');
      return res;
    },
    search:  async (data) => {
      var res = await apiSudregObjaveInstance.post('/search', data);
      return res;
    },
  }

  static SudregApi = {
    checkProtected: async () => {
      const res = await apiSudregApiInstance.get('/check_protected');
      return res.ok ? res.data : this.processError(res, 'SudregApi');
    },
    search:  async (data) => {
      var res = await apiSudregApiInstance.post('/search', data);
      return res.ok ? res.data : this.processError(res, 'SudregApi');
    },
  }

  static SudObjave = {
    checkProtected: async () => {
      const res = await apiSudObjaveInstance.get('/check_protected');
      return res;
    },
    search:  async (data) => {
      var res = await apiSudObjaveInstance.post('/search', data);
      return res;
    },
  }

  static Dozvole = {
    checkProtected: async () => {
      const res = await apiDozvoleInstance.get('/check_protected');
      return res;
    },
    search:  async (data) => {
      data.table = 'Zg';
      var res = await apiDozvoleInstance.post('/search', data);
      return res;
    },
  }

  static Status = {
    checkProtected: async () => {
      const res = await apiStatusInstance.get('/check_protected');
      return res.ok ? res.data : this.processError(res, 'Status');;
    },
    get: async () => {
      const res = await apiStatusInstance.get('/get_status');
      return res.ok ? res.data : this.processError(res, 'Status');;
    },
  }

  static PoslovniImenik = {
    checkProtected: async () => {
      const res = await apiPoslovniImenikInstance.get('/check_protected');
      return res.ok ? res.data : this.processError(res, 'PoslovniImenik');
    },
    search:  async (data) => {
      var res = await apiPoslovniImenikInstance.post('/search', data);
      return res.ok ? res.data : this.processError(res, 'PoslovniImenik');
    },
  }

  static Facebook = {
    checkProtected: async () => {
      const res = await apiFacebookInstance.get('/check_protected');
      return res;
    },
    search:  async (data) => {
      var res = await apiFacebookInstance.post('/search', data);
      return res;
    },
  }

  static ObrtniRegistar = {
    checkProtected: async () => {
      const res = await apiObrtniRegistarInstance.get('/check_protected');
      return res;
    },
    search:  async (data) => {
      var res = await apiObrtniRegistarInstance.post('/search', data);
      return res;
    },
  }
}
