import React from 'react';
import * as Styled from "./style";

const BasicTable = (props) => {
    return(
        <Styled.Table>
            <div className="horizontal_scroll">
                <table>
                    <thead>
                        <tr>
                            {props.columns.map((x, i) => {return <th key={i}>{x}</th>})}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((line, i) => {return <tr key={i}>
                            {line[0] !== 'COLSPAN' ? line.map((x, i2) => {return <td key={i2}>{x}</td>}) : <td colSpan={line[1]}>{line[2]}</td>}
                        </tr>})}
                    </tbody>
                </table>
            </div>
        </Styled.Table>
    )
}

export default BasicTable;