import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import AsyncStorage from '@react-native-community/async-storage';
import Api from '../api.js'
import { Button, FormGroup, FormControl, FormLabel, Row } from "react-bootstrap";
import "./css/Login.css";
import "../App.css";

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: props.passwordReset === null ? '' : props.passwordReset.email,
      passwordToken: props.passwordReset === null ? null : props.passwordReset.passwordToken,
      password: '',
      password2: '',
      isAuthenticated: props.isAuthenticated,
      show: props.passwordReset === null ? 'login' : 'password_reset2'
    };
    this.reloadApp = props.reloadApp
  }

  async componentDidMount(){
  }

  validateForm = () => {
    return this.state.email.length > 0 && (
      (this.state.show === 'password_reset') ||
      (this.state.show === 'password_reset2' && this.state.password.length > 0 && this.state.password === this.state.password2) ||
      (this.state.show === 'login' && this.state.password.length > 0) ||
      (this.state.show === 'register' && this.state.password.length > 0 && this.state.username.length > 0)
    )
  }

  submit = (event) => {
    event.preventDefault();
    const { username, email, password, passwordToken } = this.state;
    try {
      if (this.state.show === 'login') this.attemptLogin({ email, password });
      else if (this.state.show === 'register') this.attemptRegister({ username, email, password });
      else if (this.state.show === 'password_reset') this.attemptReset({ email });
      else if (this.state.show === 'password_reset2') this.attemptReset({ email, password, passwordToken });
    } catch (error) {
      console.log(error);
    }
  }

  attemptLogin = async (data) => {
    const response = await Api.login(data);
    if (!response.data || !response.data.message){
      alert('Unable to communicate to "Auth" Backend');
    }
    else if (response.data.message === 'ERROR'){
      alert(response.data.data)
    }
    else if (response.data.message === 'OK'){
      console.log(response.data);
      Api.saveToken(response.data.jwt);
      try {
        await AsyncStorage.setItem('userHasAuthenticated', 'true');
        await AsyncStorage.setItem('jwt', response.data.jwt);
        await AsyncStorage.setItem('userId', response.data.id);
        this.reloadApp(response.data.data, response.data.jwt);
        this.setState({isAuthenticated: true})
      } catch (error) {
        console.log(error);
      }
    }
    else alert('UNKNOWN ERROR 2');
  };

  attemptRegister = async (data) => {
    const response = await Api.register(data);
    if (!response.data || !response.data.message){
      alert('Unable to communicate to "Auth" Backend');
    }
    else if (response.data.message === 'ERROR'){
      alert(response.data.data)
    }
    else if (response.data.message === 'OK' && response.data.data === 'CREATED'){
      alert('Account created. We have sent an activation link to the email provided. Please click on it and then log in.');
      this.setState({ show: 'login' });
    }
    else alert('UNKNOWN ERROR 2');
  };

  attemptReset = async (data) => {
    const response = await Api.reset(data);
    if (!response.data || !response.data.message){
      alert('Unable to communicate to "Auth" Backend');
    }
    else if (response.data.message === 'ERROR'){
      alert(response.data.data)
    }
    else if (response.data.message === 'OK' && (response.data.data === 'SENT_PASSWORD_RESET_TOKEN' || response.data.data === 'RESENT_PASSWORD_RESET_TOKEN')){
      alert('We have sent a password reset link to the email provided. Please click on it and then change your password.');
      this.setState({ show: 'login' });
    }
    else if (response.data.message === 'OK' && response.data.data === 'PASSWORD_CHANGED'){
      alert('Password has been changed. You can now log in.');
      this.setState({ show: 'login' });
    }
    else alert('UNKNOWN ERROR 2');
  };

  render(){
    const { username, email, password, password2, show } = this.state;
    return this.state.isAuthenticated ? (
      <Redirect to="/" />
    ) : (
      <>
      <div className="container login_container">
        <div className="row">
          <div className="col-sm">
            <h1 className="page_title">{ show === 'login' ? 'Login' : show === 'register' ? 'Register' : 'Reset Password' }</h1>
            <form onSubmit={this.submit} className="login_form">
              { show === 'register' && <FormGroup controlId="username">
                <FormLabel>Username</FormLabel>
                <FormControl
                  autoFocus
                  type="text"
                  className="login_input"
                  value={username}
                  onChange={e => this.setState({username: e.target.value})}
                />
              </FormGroup>}
              <FormGroup controlId="email">
                <FormLabel>Email</FormLabel>
                <FormControl
                  autoFocus
                  type="email"
                  className="login_input"
                  value={email}
                  onChange={e => this.setState({email: e.target.value})}
                />
              </FormGroup>
              { show !== 'password_reset' && <FormGroup controlId="password">
                <FormLabel>Password</FormLabel>
                <FormControl
                  className="login_input"
                  autoComplete="on"
                  value={password}
                  onChange={e => this.setState({password: e.target.value})}
                  type="password"
                />
              </FormGroup>}
              { show === 'password_reset2' && <FormGroup controlId="password2">
                <FormLabel>Confirm Password</FormLabel>
                <FormControl
                  className="login_input"
                  autoComplete="on"
                  value={password2}
                  onChange={e => this.setState({password2: e.target.value})}
                  type="password"
                />
              </FormGroup>}
              <Button disabled={!this.validateForm()} type="submit">
                { show === 'login' ? 'Login' : show === 'register' ? 'Register' : 'Reset password' }
              </Button>
            </form>
            <Row>
              { show === 'login' && <Button onClick={() => this.setState({ show: 'password_reset' })}>
                Forgot password
              </Button>}
              <Button onClick={() => this.setState({ show: show === 'login' ? 'register' : 'login' })}>
                { show === 'login' ? 'Create account' : 'Login' }
              </Button>
            </Row>
          </div>
        </div>
      </div>
      </>
    );  
  }
}

export default Login;