import React, {useState} from 'react';
import { Modal, Table, Button } from "react-bootstrap";
import {fromLonLat} from 'ol/proj';
import { boundingExtent } from "ol/extent";
import 'ol/ol.css';
import {RMap, ROSM, RLayerVector, RFeature} from 'rlayers';
import { RStyle, RIcon } from "rlayers/style";
import "./OLMap.css";
import { Point } from "ol/geom";
import locationIcon from "../img/location.svg";

import "./CustomModal.css";

const CustomModal = (props) => {
  const {isModalVisible, modalData, className, title, greyedOut} = props;
  console.log(isModalVisible, modalData, className, title, greyedOut);
  const [map, setMap] = useState(null);
  if (!isModalVisible && map !== null) setMap(null);
  if (modalData && modalData.type==='arrayWithMap' && map === null) setTimeout(() => {
    setMap(renderMap(fromLonLat([+modalData.data[modalData.location].split(', ')[1], +modalData.data[modalData.location].split(', ')[0]])));
  }, 1);

  function renderMap(location){
    var extent = boundingExtent([fromLonLat([12, 42]), fromLonLat([21, 46.7])]);
    return <RMap
      width={"100%"}
      height={"35vh"}
      initial={{ center: location, zoom: 14 }}
      extent={extent}
    >
      <ROSM />
      <RLayerVector zIndex={10}>
        <RFeature geometry={new Point(location)}>
          <RStyle>
            <RIcon src={locationIcon} anchor={[0.5, 0.8]} />
          </RStyle>
        </RFeature>
      </RLayerVector>
    </RMap>
  }

  return (modalData && ['prerender', 'array', 'arrayWithMap', 'html', 'text'].includes(modalData.type) && modalData.data) ? (
    <Modal dialogClassName={className} style={{zIndex: greyedOut ? 1030 : 1050}} show={isModalVisible} onHide={props.onHide}>
      <Modal.Title style={{padding: 10}}>
        {title}
        <Button className='btn-danger closeButton' onClick={props.onHide}>X</Button>
      </Modal.Title>
      {isModalVisible && modalData.type === 'prerender' && <Modal.Body>{modalData.data}</Modal.Body>}
      {isModalVisible && modalData.type === 'array' && <Modal.Body><Table style={{marginLeft: 20, marginRight: 20}}><tbody>{modalData.columns.map(col => <tr key={col}><td>{col}:</td><td>{modalData.data[col]}</td></tr>)}</tbody></Table></Modal.Body>}
      {isModalVisible && modalData.type === 'arrayWithMap' && <Modal.Body><Table style={{marginLeft: 20, marginRight: 20}}><tbody>{modalData.columns.map(col => <tr key={col}><td>{col}:</td><td>{modalData.data[col]}</td></tr>)}<tr key={-1}><td colSpan="2">{map}</td></tr></tbody></Table></Modal.Body>}
      {isModalVisible && modalData.type === 'html' && <Modal.Body dangerouslySetInnerHTML={{__html: modalData.data.replace(/<a.*?>/sg, '').replace(/<\\a>/sg, '')}}/>}
      {isModalVisible && modalData.type === 'text' && <Modal.Body><pre style={{whiteSpace: 'pre-wrap'}}>{modalData.data}</pre></Modal.Body>}
    </Modal>) : <></>
}
export default CustomModal;