import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import AsyncStorage from '@react-native-community/async-storage';
import Api from './api.js'
import logo from './img/anywhere.png';
import jwt_decode from "jwt-decode";

class App extends Component {

  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      isAuthenticating: true,
      passwordReset: null,
      isAuthenticated: null,
      roles: [],
      title: 'Anywhere Data'
    };
  }

  async componentDidMount(){
    if (window.location.pathname === '/activate'){
      const url = new URL(window.location);
      if (url.searchParams.get('email') !== null && url.searchParams.get('activationToken') != null){
        const response = await Api.activate(url.searchParams.get('email'), url.searchParams.get('activationToken'));
        if (!response.data || !response.data.message){
          alert('Unable to communicate to "Auth" Backend');
        }
        else if (response.data.message === 'ERROR'){
          alert(response.data.data)
        }
        else if (response.data.message === 'OK' && response.data.data === 'USER_ACTIVATED'){
          alert('Account activated. You can now log in.');
          this.setState({ register: false });
        }
        else alert('UNKNOWN ERROR 2');
      }
    }
    else if (window.location.pathname === '/reset'){
      const url = new URL(window.location);
      if (url.searchParams.get('email') !== null && url.searchParams.get('passwordToken') != null){
        this.setState({passwordReset: {email: url.searchParams.get('email'), passwordToken: url.searchParams.get('passwordToken')}});
      }
    }
    console.log('App.componentDidMount', await AsyncStorage.getItem('userHasAuthenticated'));
    const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
    const jwt = await AsyncStorage.getItem('jwt');
    if (userHasAuthenticated === 'true') {
      Api.saveToken(jwt);
      Api.setLogout(this.handleLogout);
      var decoded = jwt_decode(jwt);
      this.setState({ isAuthenticating: false, isAuthenticated: true, roles: decoded.UserRole ? decoded.UserRole.map(x => x.module) : [] })
    }
    else{
      try {
        await AsyncStorage.setItem('userHasAuthenticated', 'false');
        await AsyncStorage.setItem('jwt', null);
        await AsyncStorage.setItem('userId', null);
        this.setState({ isAuthenticating: false, isAuthenticated: false })
      } catch (e) {
        console.log('saving error', e)
      }
    }
  }

  handleLogout = async () => {
    console.log('handleLogout');
    try {
      Api.saveToken('');
      await AsyncStorage.setItem('userHasAuthenticated', 'false');
      await AsyncStorage.setItem('jwt', null);
      await AsyncStorage.setItem('userId', null);
      this.setState({isAuthenticated: false})
    } catch (e) {
      console.log('saving error', e)
    }
  }

  reloadApp = (user, jwt) => {
    console.log('reloadApp', user);
    if (user && user !== null){
      var decoded = jwt_decode(jwt);
      this.setState({ isAuthenticated: true, roles: decoded.UserRole.map(x => x.module), passwordReset: null })
    }
  }

  changeTitle = (title) => {
    this.setState({title: title});
  }

  render(){
    return !this.state.isAuthenticating && (
      <div className="App">
        { this.state.isAuthenticated !== null && this.state.isAuthenticated ? (
            <header className="main_header">
              <div className="container page_header">
              <div className="logo_box">
                  <Link className="nav_item" to="/"><img className="logo" alt="Anywhere DATA logo" src={logo} /></Link>
                </div>
                <div className="titleBox">
                  <div className="titleItem">
                    {this.state.title}
                  </div>
                </div>
                <nav className="main_nav">
                  <NavLink className="nav_item" activeClassName="active" exact to="/"><i className="las la-globe"></i> <span>Home</span></NavLink>
                  { this.state.roles.indexOf('Admin') > -1 && <NavLink className="nav_item" activeClassName="active" exact to="/admin"><i className="las la-wrench"></i> <span>Admin</span></NavLink> }
                  { this.state.roles.indexOf('Status') > -1 && <NavLink className="nav_item" activeClassName="active" exact to="/status"><i className="las la-wrench"></i> <span>Status</span></NavLink> }
                  { this.state.roles.indexOf('Gruntovnica') > -1 && <NavLink className="nav_item" activeClassName="active" to="/gruntovnica"><i className="las la-user"></i> <span>Gruntovnica</span></NavLink> }
                  { this.state.roles.indexOf('KatastarPL') > -1 && <NavLink className="nav_item" activeClassName="active" to="/katastar_posjedovni_list"><i className="las la-user"></i> <span>Katastar (Posjedovni listovi)</span></NavLink> }
                  { this.state.roles.indexOf('PolozeniUgovori') > -1 && <NavLink className="nav_item" activeClassName="active" to="/polozeni_ugovori"><i className="las la-user"></i> <span>Polozeni Ugovori</span></NavLink> }
                  { this.state.roles.indexOf('Adresar') > -1 && <NavLink className="nav_item" activeClassName="active" to="/adresar"><i className="las la-user"></i> <span>Adresar</span></NavLink> }
                  { this.state.roles.indexOf('Imenik') > -1 && <NavLink className="nav_item" activeClassName="active" to="/imenik"><i className="las la-user"></i> <span>Imenik</span></NavLink> }
                  { this.state.roles.indexOf('Registracije') > -1 && <NavLink className="nav_item" activeClassName="active" to="/registracije"><i className="las la-user"></i> <span>Registracije</span></NavLink> }
                  { this.state.roles.indexOf('Registracije') > -1 && <NavLink className="nav_item" activeClassName="active" to="/registracije_raw"><i className="las la-user"></i> <span>Registracije Raw</span></NavLink> }
                  { this.state.roles.indexOf('SudregObjave') > -1 && <NavLink className="nav_item" activeClassName="active" to="/sudreg_objave"><i className="las la-user"></i> <span>Sudreg Objave</span></NavLink> }
                  { this.state.roles.indexOf('SudregApi') > -1 && <NavLink className="nav_item" activeClassName="active" to="/sudreg_api"><i className="las la-user"></i> <span>Sudreg Api</span></NavLink> }
                  { this.state.roles.indexOf('SudObjave') > -1 && <NavLink className="nav_item" activeClassName="active" to="/sud_objave"><i className="las la-user"></i> <span>Sud Objave</span></NavLink> }
                  { this.state.roles.indexOf('Dozvole') > -1 && <NavLink className="nav_item" activeClassName="active" to="/dozvole"><i className="las la-user"></i> <span>Dozvole</span></NavLink> }
                  { this.state.roles.indexOf('PoslovniImenik') > -1 && <NavLink className="nav_item" activeClassName="active" to="/poslovni_imenik"><i className="las la-user"></i> <span>Poslovni Imenik</span></NavLink> }
                  { this.state.roles.indexOf('Facebook') > -1 && <NavLink className="nav_item" activeClassName="active" to="/facebook"><i className="las la-user"></i> <span>Facebook</span></NavLink> }
                  { this.state.roles.indexOf('ObrtniRegistar') > -1 && <NavLink className="nav_item" activeClassName="active" to="/obrtni_registar"><i className="las la-user"></i> <span>Obrtni Registar</span></NavLink> }
                  <Link className="nav_item" to="/" onClick={ this.handleLogout }><i className="las la-sign-out-alt"></i> <span>Logout</span></Link>
                </nav>
              </div>
            </header>
          ) : (
            <>
              <header className="main_header">
                <img className="login_logo" alt="Login" src={logo} />
              </header>
            </>
          )
        }
        <Routes handleLogout={ this.handleLogout } reloadApp={ this.reloadApp } isAuthenticated={ this.state.isAuthenticated } passwordReset={ this.state.passwordReset } changeTitle={ this.changeTitle } />
      </div>
    );
  }
}

export default App;
