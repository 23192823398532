import React, { Component } from 'react';
import Loader from "../components/Loader.js";
import Api from '../api.js'
import "./css/KatastarPL.css";
import { Button, FormGroup, FormControl, FormSelect, Container, Row, Col, InputGroup, Dropdown } from "react-bootstrap";
import AsyncStorage from '@react-native-community/async-storage';
import BasicTable from '../components/table/BasicTable';
import CustomModal from '../components/CustomModal.js';

class KatastarPL extends Component {

  constructor(props) {
    console.log(props);

    super(props);
    this.state = {
      isLoaded: false,
      isAuthenticated: props.isAuthenticated,
      searchText: '',
      searchType: 'Tsv',
      searchPodrucniUred: 'Prikaži sve',
      opcijePodrucniUred: [],
      renderPodrucniUred: false,
      searchPodrucniUredOdjel: 'Prikaži sve',
      opcijePodrucniUredOdjel: [],
      renderPodrucniUredOdjel: false,
      searchOpcina: 'Prikaži sve',
      opcijeOpcina: [],
      renderOpcina: false,
      searchPosjedovniList: 'Prikaži sve',
      opcijePosjedovniList: [],
      renderPosjedovniList: false,
      searchResult: {},
      page: 0,
      pages: [],
      isModalVisible: false,
      modalData: null
    };
    this.handleLogout = props.handleLogout
    this.searchTextPersist = '';
    this.searchTypePersist = 'Tsv';
    this.searchPodrucniUredPersist = '';
    this.searchPodrucniUredOdjelPersist = '';
    this.searchOpcinaPersist = '';
    this.searchPosjedovniListPersist = '';
    this.page = 1;
    props.changeTitle('Anywhere Data / Katastar (Posjedovni list)');
  }

  async componentDidMount(){
      this.setState({isLoaded: true});
      const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
      if (userHasAuthenticated === 'true' && this.state.isAuthenticated) this.checkBackend();
      const query = new URLSearchParams(window.location.search);
      if (query.get('uuid') !== null && this.state.modalHTML === null){
        this.showHTMLData(query.get('uuid'));
      }
  
  }

  countToPages = (count) => {
    var tempArray = []
    for (let i = 1; i <= Math.ceil(count/20); i++) {
      tempArray.push(i)
    }
    return tempArray;
  }

  checkBackend = async () => {
    const checkProtected = await Api.KatastarPL.checkProtected();
    if (checkProtected.error) alert(checkProtected.message);
    else console.log(checkProtected);
  }

  search = (event) => {
    event.preventDefault();
    this.searchTextPersist = this.state.searchText;
    this.searchTypePersist = this.state.searchType;
    this.searchPodrucniUredPersist = this.state.searchPodrucniUred;
    this.searchPodrucniUredOdjelPersist = this.state.searchPodrucniUredOdjel;
    this.searchOpcinaPersist = this.state.searchOpcina;
    this.searchPosjedovniListPersist = this.state.searchPosjedovniList;
    this.page = 1;
    this.setState({
      isLoaded: false
    });
    this.getSearchData();
  }

  searchPage = (event) => {
    event.preventDefault();
    this.page = +event.target.value;
    this.setState({
      isLoaded: false
    });
    this.getSearchData();
  }

  showHTML = async (event) => {
    event.preventDefault();
    this.showHTMLData(event.target.value);
  }

  showHTMLData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isModalVisible: true,
      modalData: null
    });
    var htmlData = await Api.KatastarPL.html(uuid);
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isModalVisible: false
      });
    }
    else {
      this.setState({
        modalData: {
          type: 'html',
          data: htmlData.data.html
        }
      })
    }
  }

  showGruntovnica = async (event) => {
    event.preventDefault();
    this.showGruntovnicaHTMLData(event.target.value);
  }

  showGruntovnicaHTMLData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isModalVisible: true,
      modalData: null
    });
    var htmlData = await Api.Gruntovnica.html(uuid);
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isModalVisible: false
      });
    }
    else {
      this.setState({
        modalData: {
          type: 'html',
          data: htmlData.data.html
        }
      })
    }
  }

  showAdresar = async (event) => {
    event.preventDefault();
    this.showAdresarData(event.target.value);
  }

  showAdresarData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isModalVisible: true,
      modalData: null
    });
    var htmlData = await Api.Adresar.search({type: 'Uuid', text: uuid});
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isModalVisible: false
      });
    }
    else {
      this.setState({
        modalData: {
          type: 'arrayWithMap',
          columns: ['zupanija', 'jls', 'naselje', 'ulica', 'kucni_broj'],
          location: 'lokacija',
          data: htmlData.data[0]
        }
      })
    }
  }

  getSearchData = async () => {
    try {
      var options = {
        type: this.searchTypePersist,
        text: this.searchTextPersist,
        page: this.page
      };
      if (this.searchPodrucniUredPersist !== 'Prikaži sve') options.podrucni_ured = this.searchPodrucniUredPersist;
      if (this.searchPodrucniUredOdjelPersist !== 'Prikaži sve') options.podrucni_ured_odjel = this.searchPodrucniUredOdjelPersist;
      if (this.searchOpcinaPersist !== 'Prikaži sve') options.opcina = this.searchOpcinaPersist;
      if (this.searchPosjedovniListPersist !== 'Prikaži sve') options.posjedovni_list = this.searchPosjedovniListPersist;
      const searchResult = await Api.KatastarPL.search(options);
      if (searchResult.error){
        alert(searchResult.message);
        this.setState({
          isLoaded: true
        });
      }
      else {
        this.setState({
          page: this.page,
          pages: searchResult.count !== null ? this.countToPages(searchResult.count) : this.state.pages,
          searchResult: searchResult.data,
          isLoaded: true
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  renderPage = () => {
    const columns = [ 'Vanjski url', 'Dokument', 'Adresar', 'Gruntovnica', 'Podrucni ured', 'Podrucni ured odjel', 'Datum', 'Katastarska Općina', 'Posjedovni List' ];
    const data = this.state.searchResult.map(x => [
      <a href={x.url} target="_blank" rel="noreferrer">Posjedovni list</a>,
      <Button value={x.uuid} onClick={this.showHTML}>dokument</Button>,
      <div style={x.Adresar.length > 2 ? {maxHeight: 100, overflowY: 'scroll'} : {}}>{x.Adresar.map(y => <Button style={{marginTop: 5}} key={y.adresarUuid} value={y.adresarUuid} onClick={this.showAdresar}>Adresar</Button>)}</div>,
      <div style={x.Gruntovnica.length > 2 ? {maxHeight: 100, overflowY: 'scroll'} : {}}>{x.Gruntovnica.map(y => <Button style={{marginTop: 5}} key={y.gruntovnicaUuid} value={y.gruntovnicaUuid} onClick={this.showGruntovnica}>Gruntovnica</Button>)}</div>,
      x.podrucni_ured,
      x.podrucni_ured_odjel.replace('ZEMLJIŠNOKNJIŽNI ODJEL ', ''),
      x.stanje_datum.substr(0,11),
      x.katastarska_opcina,
      x.posjedovni_list
    ]);
    return <BasicTable
      columns={columns}
      data={data}
    />
  }

  pageRange = (page, pageCount) => {
    var start = page - 2,
        end = page + 2;
    if (end > pageCount) {
        start -= (end - pageCount);
        end = pageCount;
    }
    if (start <= 0) {
        end += ((start - 1) * (-1));
        start = 1;
    }
    end = end > pageCount ? pageCount : end;
    return {
        start: start,
        end: end
    };
  }

  renderPageRow = () => {
    var pagesLocal = [];
    var range = this.pageRange(this.state.page, this.state.pages.length);
    var start = range.start;
    var end = range.end;
    if (start > 1 && this.state.pages.length > 5){
      pagesLocal.push(this.state.pages[0]);
    } else if (end < this.state.pages.length) end++;
    if (end === this.state.pages.length && start > 1) start --;
    for (var page_id = start; page_id <= end; page_id++) {
      pagesLocal.push(this.state.pages[page_id-1]);
    }
    
    if (end < this.state.pages.length){
      pagesLocal.push(this.state.pages[this.state.pages.length - 1]);
    }
    
    return (<Row>{ pagesLocal.map(page => <Button disabled={page === this.state.page} value={page} key={page} onClick={this.searchPage}>{page}</Button>)}</Row>)
  }

  populatePodrucniUred = async () => {
    try {
      if (this.state.opcijePodrucniUred.length === 0){
        const response = await Api.KatastarPL.list({type: 'podrucni_ured'}); 
        if (response.error) alert(response.message);
        else{
          this.setState({
            opcijePodrucniUred: response.data.map( x=> x.podrucni_ured)
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  populatePodrucniUredOdjel = async () => {
    try {
      if (this.state.opcijePodrucniUredOdjel.length === 0){
        let options = {type: 'podrucni_ured_odjel'};
        if (this.state.searchPodrucniUred !== 'Prikaži sve') options.podrucni_ured=this.state.searchPodrucniUred;
        const response = await Api.KatastarPL.list(options); 
        if (response.error) alert(response.message);
        else{
          this.setState({
            opcijePodrucniUredOdjel: response.data.map( x=> x.podrucni_ured_odjel)
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  populateOpcina = async () => {
    try {
      if (this.state.opcijeOpcina.length === 0){
        let options = {type: 'opcina'};
        if (this.state.searchPodrucniUred !== 'Prikaži sve') options.podrucni_ured=this.state.searchPodrucniUred;
        if (this.state.searchPodrucniUredOdjel !== 'Prikaži sve') options.podrucni_ured_odjel=this.state.searchPodrucniUredOdjel;
        if (this.state.searchPodrucniUred !== 'Prikaži sve' || this.state.searchPodrucniUredOdjel !== 'Prikaži sve'){
          const response = await Api.KatastarPL.list(options);
          if (response.error) alert(response.message);
          else{
            this.setState({
              opcijeOpcina: response.data.map( x=> x.katastarska_opcina).sort((a, b) => a.replace(/.*?, /, '').localeCompare(b.replace(/.*?, /, '')))
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  PopulatePosjedovniList = async () => {
    try {
      if (this.state.opcijePosjedovniList.length === 0){
        let options = {type: 'posjedovni_list'};
        if (this.state.searchPodrucniUred !== 'Prikaži sve') options.podrucni_ured=this.state.searchPodrucniUred;
        if (this.state.searchPodrucniUredOdjel !== 'Prikaži sve') options.podrucni_ured_odjel=this.state.searchPodrucniUredOdjel;
        if (this.state.searchOpcina !== 'Prikaži sve'){
          options.opcina=this.state.searchOpcina;
          const response = await Api.KatastarPL.list(options);
          if (response.error) alert(response.message);
          else{
            this.setState({
              opcijePosjedovniList: response.data.map( x=> x.posjedovni_list)
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  render(){
    return (
      <div className="container page_container">
        { !this.state.isLoaded ? <Loader></Loader> : <>
          <div>
            <Container fluid>
              <form onSubmit={this.search} className="katastar_posjedovni_list_form">
                <FormGroup controlId="search">
                  <Row>
                    <Col>
                      <Dropdown
                        style={{width: '100%'}}
                        onToggle={e => {
                          this.populatePodrucniUred();
                          this.setState({renderPodrucniUred: e})
                        }}
                        onSelect={ e => this.setState({ 
                          searchPodrucniUred: e, 
                          opcijePodrucniUredOdjel: [],
                          searchPodrucniUredOdjel: 'Prikaži sve',
                          opcijeOpcina: [],
                          searchOpcina: 'Prikaži sve',
                          opcijePosjedovniList: [],
                          searchPosjedovniList: 'Prikaži sve'
                        })}>
                        <Dropdown.Toggle style={{width: '100%'}}>
                        { this.state.searchPodrucniUred === 'Prikaži sve' ? 'Odaberi područni ured' : this.state.searchPodrucniUred }
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '100%'}}>
                          <Dropdown.Item eventKey={ 'Prikaži sve' } key={ -1 }>Prikaži sve</Dropdown.Item>
                          { this.state.renderPodrucniUred && this.state.opcijePodrucniUred.length > 0 ? this.state.opcijePodrucniUred.map((key, index) => <Dropdown.Item eventKey={ key } key={ index }>{ key }</Dropdown.Item>) : <Loader>LOADING</Loader> }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <Dropdown
                        style={{width: '100%'}}
                        onToggle={e => {
                          this.populatePodrucniUredOdjel();
                          this.setState({renderPodrucniUredOdjel: e})
                        }}
                        onSelect={ e => this.setState({ 
                          searchPodrucniUredOdjel: e,
                          opcijeOpcina: [],
                          searchOpcina: 'Prikaži sve',
                          opcijePosjedovniList: [],
                          searchPosjedovniList: 'Prikaži sve'
                        })}>
                        <Dropdown.Toggle style={{width: '100%'}}>
                        { this.state.searchPodrucniUredOdjel === 'Prikaži sve' ? 'Odaberi zemljišnoknjižni odjel' : this.state.searchPodrucniUredOdjel.replace('ZEMLJIŠNOKNJIŽNI ODJEL ', '') }
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '100%'}}>
                          <Dropdown.Item eventKey={ 'Prikaži sve' } key={ -1 }>Prikaži sve</Dropdown.Item>
                          { this.state.renderPodrucniUredOdjel && this.state.opcijePodrucniUredOdjel.length > 0 ? this.state.opcijePodrucniUredOdjel.map((key, index) => <Dropdown.Item eventKey={ key } key={ index }>{ key.replace('ZEMLJIŠNOKNJIŽNI ODJEL ', '') }</Dropdown.Item>) : <Loader>LOADING</Loader> }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <Dropdown
                        style={{width: '100%'}}
                        onToggle={e => {
                          this.populateOpcina();
                          this.setState({renderOpcina: e})
                        }}
                        onSelect={ e => this.setState({
                          searchOpcina: e,
                          opcijePosjedovniList: [],
                          searchPosjedovniList: 'Prikaži sve'
                        })}>
                        <Dropdown.Toggle style={{width: '100%'}} disabled={this.state.searchPodrucniUred === 'Prikaži sve' && this.state.searchPodrucniUredOdjel === 'Prikaži sve'}>
                        { this.state.searchOpcina === 'Prikaži sve' ? 'Odaberi općinu' : this.state.searchOpcina.replace(/.*?, /, '') }
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '100%'}}>
                          <Dropdown.Item eventKey={ 'Prikaži sve' } key={ -1 }>Prikaži sve</Dropdown.Item>
                          { this.state.renderOpcina && this.state.opcijeOpcina.length > 0 ? this.state.opcijeOpcina.map((key, index) => <Dropdown.Item eventKey={ key } key={ index }>{ key.replace(/.*?, /, '') }</Dropdown.Item>) : <Loader>LOADING</Loader> }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <Dropdown
                        style={{width: '100%'}}
                        onToggle={e => {
                          this.PopulatePosjedovniList();
                          this.setState({renderPosjedovniList: e})
                        }}
                        onSelect={ e => this.setState({ searchPosjedovniList: e }) }>
                        <Dropdown.Toggle disabled={ this.state.searchOpcina === 'Prikaži sve' } style={{width: '100%'}}>
                          Odaberi zemljišnoknjižni uložak
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '100%'}}>
                          <Dropdown.Item eventKey={ 'Prikaži sve' } key={ -1 }>Prikaži sve</Dropdown.Item>
                          { this.state.renderPosjedovniList && this.state.opcijePosjedovniList.length > 0 ? this.state.opcijePosjedovniList.map((key, index) => <Dropdown.Item eventKey={ key } key={ index }>{ key }</Dropdown.Item>) : <Loader>LOADING</Loader> }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <FormControl placeholder="Prikaži sve" disabled={ this.state.searchOpcina === 'Prikaži sve' } type="text" value={ this.state.searchPosjedovniList === 'Prikaži sve' ? '' : this.state.searchPosjedovniList } onChange={e => this.setState({ searchPosjedovniList: e.target.value }) }/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputGroup>
                        <FormControl
                          type="text"
                          value={this.state.searchText}
                          onChange={e => {this.setState({ searchText: e.target.value })}}
                        />
                        <FormSelect
                          value={this.state.searchType}
                          onChange={e => this.setState({ searchType: e.target.value })}
                        >
                          <option value="Tsv">Ignoriraj redosljed riječi</option>
                          <option value="TsvPhrase">Matchiraj rečenicu</option>
                        </FormSelect>
                        <Button type="submit">
                          Traži
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            </Container>
            { this.state.pages.length > 0 &&
              <>
                <div>
                  { Object.keys(this.state.searchResult).length > 0 && this.renderPage() }
                </div>
                <div>
                  { this.renderPageRow() }
                </div>
              </>
            }
          </div>
        </> }
        <CustomModal className='modal-630' isModalVisible={this.state.isModalVisible} modalData={this.state.modalData} onHide={() => this.setState({isModalVisible: false})}/>
      </div>
    );  
  }

}

export default KatastarPL;