import React, { Component } from 'react';
import Loader from "../components/Loader.js";
import OLMap from "../components/OLMap.js";
import Api from '../api.js'
import AsyncStorage from '@react-native-community/async-storage';
import "./css/Adresar.css";
import { Button, FormGroup, FormControl, FormSelect, Container, Row, Col, InputGroup, Dropdown } from "react-bootstrap";
import {Column, Table} from 'react-virtualized';
import 'react-virtualized/styles.css';
import CustomModal from '../components/CustomModal.js';

class Adresar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isAuthenticated: props.isAuthenticated,
      searchText: '',
      searchType: 'Tsv',
      searchZupanija: 'Prikaži sve',
      opcijeZupanija: [],
      renderZupanija: false,
      searchNaselje: 'Prikaži sve',
      opcijeNaselje: [],
      renderNaselje: false,
      searchJLS: 'Prikaži sve',
      opcijeJLS: [],
      renderJLS: false,
      searchUlica: 'Prikaži sve',
      opcijeUlica: [],
      renderUlica: false,
      searchKbr: 'Prikaži sve',
      opcijeKbr: [],
      renderKbr: false,
      searchResult: [],
      page: 0,
      pages: [],
      isModalVisible: false,
      modalData: null,
      isDocumentModalVisible: false,
      documentModalData: null,
      showMarkerMouseOver: [],
      searchUrl: null,
      showAllMarkers: false,
      mapLocation: null
    };
    this.handleLogout = props.handleLogout
    this.searchTextPersist = '';
    this.searchTypePersist = 'Tsv';
    this.searchZupanijaPersist = '';
    this.searchJlsPersist = '';
    this.searchNaseljePersist = '';
    this.searchUlicaPersist = '';
    this.searchKbrPersist = '';
    this.page = 1;
    props.changeTitle('Anywhere Data / Adresar');
  }

  async componentDidMount(){
    this.setState({isLoaded: true});
    const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
    if (userHasAuthenticated === 'true' && this.state.isAuthenticated) this.checkBackend();
  }

  checkBackend = async () => {
    const checkProtected = await Api.Adresar.checkProtected();
    if (checkProtected.error) alert(checkProtected.message);
    else console.log(checkProtected);
  }

  populateZupanija = async () => {
    try {
      if (this.state.opcijeZupanija.length === 0){
        const response = await Api.Adresar.list({type: 'zupanija'}); 
        if (response.error) alert (response.message);
        else {
          this.setState({
            opcijeZupanija: response.data.sort((a, b) => a.zupanija.localeCompare(b.zupanija))
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  populateNaselje = async () => {
    try {
      if (this.state.opcijeNaselje.length === 0){
        let options = {type: 'naselje'};
        if (this.state.searchZupanija !== 'Prikaži sve') options.zupanijaId = this.state.searchZupanija;
        const response = await Api.Adresar.list(options);
        if (response.error) alert (response.message);
        else {
          this.setState({
            opcijeJLS: [...new Map(response.data.map(item => [item['jls'], item])).values()].sort((a, b) => a.jls.localeCompare(b.jls)),
            opcijeNaselje: response.data.sort((a, b) => a.naselje.localeCompare(b.naselje))
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  populateUlica = async () => {
    try {
      if (this.state.opcijeUlica.length === 0){
        let options = {type: 'ulica'};
        if (this.state.searchNaselje !== 'Prikaži sve') options.naseljeId=this.state.searchNaselje;
        if (this.state.searchNaselje !== 'Prikaži sve'){
          const response = await Api.Adresar.list(options);
          if (response.error) alert (response.message);
          else {
            this.setState({
              opcijeUlica: response.data.sort((a, b) => a.ulica.localeCompare(b.ulica))
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  PopulateKbr = async () => {
    try {
      if (this.state.opcijeKbr.length === 0){
        let options = {type: 'kbr'};
        if (this.state.searchUlica !== 'Prikaži sve') options.ulicaId=this.state.searchUlica;
        if (this.state.searchUlica !== 'Prikaži sve'){
          options.Ulica=this.state.searchUlica;
          const response = await Api.Adresar.list(options);
          if (response.error) alert (response.message);
          else {
            this.setState({
              opcijeKbr: response.data.sort((a, b) => a.kucni_broj.localeCompare(b.kucni_broj))
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  search = (event) => {
    event.preventDefault();
    this.searchTextPersist = this.state.searchText;
    this.searchTypePersist = this.state.searchType;
    this.searchZupanijaPersist = this.state.searchZupanija;
    this.searchJlsPersist = this.state.searchJLS;
    this.searchNaseljePersist = this.state.searchNaselje;
    this.searchUlicaPersist = this.state.searchUlica;
    this.searchKbrPersist = this.state.searchKbr;
    this.page = 1;
    this.setState({
      isLoaded: false
    });
    this.getSearchData();
  }

  searchPage = (event) => {
    event.preventDefault();
    this.page = +event.target.value;
    this.setState({
      isLoaded: false
    });
    this.getSearchData();
  }

  countToPages = (count) => {
    var tempArray = []
    for (let i = 1; i <= Math.ceil(count/100000); i++) {
      tempArray.push(i)
    }
    return tempArray;
  }

  getSearchData = async () => {
    try {
      var options = {
        type: this.searchTypePersist,
        text: this.searchTextPersist,
        page: this.page
      };
      if (this.searchZupanijaPersist !== 'Prikaži sve') options.zupanijaId = this.searchZupanijaPersist;
      if (this.searchJlsPersist !== 'Prikaži sve') options.jls = this.searchJlsPersist;
      if (this.searchNaseljePersist !== 'Prikaži sve') options.naseljeId = this.searchNaseljePersist;
      if (this.searchUlicaPersist !== 'Prikaži sve') options.ulicaId = this.searchUlicaPersist;
      if (this.searchKbrPersist !== 'Prikaži sve') options.kbrId = this.searchKbrPersist;
      this.setState({ searchUrl: Api.Adresar.searchUrl(options) });
      const searchResult = await Api.Adresar.search(options);
      if (searchResult.error) {
        alert (searchResult.message);
        this.setState({
          isLoaded: true
        });
      }
      else {
        this.setState({
          page: this.page,
          searchUrl: searchResult.url,
          pages: searchResult.count !== null ? this.countToPages(searchResult.count) : this.state.pages,
          searchResult: searchResult.data.map(x => {return {...x, checked: false, coords: [+x.lokacija.split(', ')[1], +x.lokacija.split(', ')[0]]}}),
          mapLocation: null,
          isLoaded: true
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  pageRange = (page, pageCount) => {
    var start = page - 2,
        end = page + 2;
    if (end > pageCount) {
        start -= (end - pageCount);
        end = pageCount;
    }
    if (start <= 0) {
        end += ((start - 1) * (-1));
        start = 1;
    }
    end = end > pageCount ? pageCount : end;
    return {
        start: start,
        end: end
    };
  }

  renderPage = () => {
    return <Table
      width={ window.innerWidth*0.5 }
      height={ window.innerHeight*0.65 }
      headerHeight={20}
      rowHeight={40}
      rowCount={Object.keys(this.state.searchResult).length}
      rowGetter={({index}) => this.state.searchResult[index]}
      onRowClick={ clickEvent => {
        if (clickEvent.event.target + '' === '[object HTMLButtonElement]'){
          console.log(clickEvent.rowData);
          clickEvent.rowData.gruntovnica=<div>{clickEvent.rowData.Gruntovnica.map(x => <Button style={{marginTop: 5}} key={x.gruntovnicaUuid} value={x.gruntovnicaUuid} onClick={this.showGruntovnica}>Gruntovnica</Button>)}</div>
          clickEvent.rowData.katastar_posjedovni_list=<div>{clickEvent.rowData.KatastarPL.map(x => <Button style={{marginTop: 5}} key={x.katastarPLUuid} value={x.katastarPLUuid} onClick={this.showKatastarPL}>Vlasnički list</Button>)}</div>
          this.setState({
            isModalVisible: true,
            modalData: {
              type: 'arrayWithMap',
              columns: ['zupanija', 'jls', 'naselje', 'ulica', 'kucni_broj', 'gruntovnica', 'katastar_posjedovni_list'],
              location: 'lokacija',
              data: clickEvent.rowData
            }
          })
        }
        else if (!this.state.showAllMarkers) this.setState({searchResult: this.state.searchResult.map(x => {return {...x, checked: x.id===clickEvent.rowData.id ? !x.checked : x.checked}})})
      }}
      onRowMouseOver={ x => {
        if (x.event.target.classList.contains('ReactVirtualized__Table__rowColumn')){
          x.event.target.parentElement.classList.add('RowMouseOver');
        }
        else {
          x.event.target.classList.add('RowMouseOver');
        }
        if (!this.state.showAllMarkers) this.setState({ showMarkerMouseOver: [x.rowData] });
      }}
      onRowMouseOut={ x => {
        if (x.event.target.classList.contains('ReactVirtualized__Table__rowColumn')){ 
          x.event.target.parentElement.classList.remove('RowMouseOver');
        }
        else {
          x.event.target.classList.remove('RowMouseOver');
        }
        if (!this.state.showAllMarkers) this.setState({ showMarkerMouseOver: [] });
      }}
    >
      <Column label="🗸" dataKey="checked" width={window.innerHeight*0.025} cellRenderer={({cellData}) => <div>{ cellData ? '🗸' : '' }</div>}/>
      <Column label="zupanija" dataKey="zupanija" width={window.innerHeight*0.225} />
      <Column label="jls" dataKey="jls" width={window.innerHeight*0.2} />
      <Column label="naselje" dataKey="naselje" width={window.innerHeight*0.2} />
      <Column label="ulica" dataKey="ulica" width={window.innerHeight*0.25} />
      <Column label="kucni_broj" dataKey="kucni_broj" width={window.innerHeight*0.1} />
      <Column label="detalji" dataKey="id" width={window.innerHeight*0.1} cellRenderer={({cellData}) => <Button value={cellData.id} onClick={console.log}>Detalji</Button>}/>
    </Table>
  }

  showKatastarPL = async (event) => {
    event.preventDefault();
    this.showKatastarPLHTMLData(event.target.value);
  }

  showKatastarPLHTMLData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isDocumentModalVisible: true,
      documentModalData: null
    });
    var htmlData = await Api.KatastarPL.html(uuid);
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isDocumentModalVisible: false
      });
    }
    else {
      this.setState({
        documentModalData: {
          type: 'html',
          data: htmlData.data.html
        }
      })
    }
  }

  showGruntovnica = async (event) => {
    event.preventDefault();
    this.showGruntovnicaHTMLData(event.target.value);
  }

  showGruntovnicaHTMLData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isDocumentModalVisible: true,
      documentModalData: null
    });
    var htmlData = await Api.Gruntovnica.html(uuid);
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isDocumentModalVisible: false
      });
    }
    else {
      this.setState({
        documentModalData: {
          type: 'html',
          data: htmlData.data.html
        }
      })
    }
  }

  renderPageRow = () => {
    var pagesLocal = [];
    var range = this.pageRange(this.state.page, this.state.pages.length);
    var start = range.start;
    var end = range.end;
    if (start > 1 && this.state.pages.length > 5){
      pagesLocal.push(this.state.pages[0]);
    } else if (end < this.state.pages.length) end++;
    if (end === this.state.pages.length && start > 1) start --;
    for (var page_id = start; page_id <= end; page_id++) {
      pagesLocal.push(this.state.pages[page_id-1]);
    }
    
    if (end < this.state.pages.length){
      pagesLocal.push(this.state.pages[this.state.pages.length - 1]);
    }
    
    return (<Row>{ pagesLocal.map(page => <Button disabled={page === this.state.page} value={page} key={page} onClick={this.searchPage}>{page}</Button>)}</Row>)
  }

  mouseMapMove=(coordinates) => {
    var closestData = null;
    var closestPoint = [0, 0];
    this.state.searchResult.forEach(element => {
      if (Math.abs(coordinates[0] - element.coords[0]) < 0.001 && Math.abs(coordinates[1] - element.coords[1]) < 0.001 && Math.abs(coordinates[0] - element.coords[0]) + Math.abs(coordinates[1] - element.coords[1]) < Math.abs(coordinates[0] - closestPoint[0]) + Math.abs(coordinates[1] - closestPoint[1])){
        closestData = element;
        closestPoint = element.coords;    
      }
    });
    if (closestData != null) this.setState({mapLocation: closestData.zupanija + ', ' + closestData.jls + ', ' + closestData.naselje + ', ' + closestData.ulica + ' ' + closestData.kucni_broj});
    else this.setState({mapLocation: null});
  }

  render(){
    var markers = [];
    this.state.showMarkerMouseOver.forEach(x => markers.push({
      coords: [+x.lokacija.split(', ')[1], +x.lokacija.split(', ')[0]],
      popup: null,
      id: 'mouseover_' + x.id
    }));
    this.state.searchResult.length > 0 && this.state.searchResult.map(x => (x.checked || this.state.showAllMarkers) && markers.push({
      coords: [+x.lokacija.split(', ')[1], +x.lokacija.split(', ')[0]],
      popup: <div className="card">
        <div>{x.zupanija}, {x.jls}</div>
        <div>{x.naselje}, {x.ulica} {x.kucni_broj}</div>
      </div>,
      id: x.id
    }));

    return (
      <div className="container page_container">
        { !this.state.isLoaded ? <Loader></Loader> : (
          <>
            <Container fluid>
              <form onSubmit={this.search} className="adresar_form">
                <FormGroup controlId="search">
                  <Row>
                    <Col>
                      <Dropdown
                        style={{width: '100%'}}
                        onToggle={e => {
                          this.populateZupanija();
                          this.setState({renderZupanija: e})
                        }}
                        onSelect={ e => this.setState({ 
                          searchZupanija: e, 
                          opcijeNaselje: [],
                          searchNaselje: 'Prikaži sve',
                          opcijeJLS: [],
                          searchJLS: 'Prikaži sve',
                          opcijeUlica: [],
                          searchUlica: 'Prikaži sve',
                          opcijeKbr: [],
                          searchKbr: 'Prikaži sve'
                        })}>
                        <Dropdown.Toggle style={{width: '100%'}}>
                        { this.state.searchZupanija === 'Prikaži sve' ? 'Odaberi zupaniju' : this.state.opcijeZupanija.filter(x => x.id === this.state.searchZupanija)[0].zupanija }
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '100%'}}>
                          <Dropdown.Item eventKey={ 'Prikaži sve' } key={ -1 }>Prikaži sve</Dropdown.Item>
                          { this.state.renderZupanija && this.state.opcijeZupanija.length > 0 ? this.state.opcijeZupanija.map((key, index) => <Dropdown.Item eventKey={ key.id } key={ index }>{ key.zupanija }</Dropdown.Item>) : <Loader>LOADING</Loader> }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <Dropdown
                        style={{width: '100%'}}
                        onToggle={e => {
                          this.populateNaselje();
                          this.setState({renderJLS: e})
                        }}
                        onSelect={ e => this.setState({ 
                          searchJLS: e,
                          opcijeUlica: [],
                          searchUlica: 'Prikaži sve',
                          opcijeKbr: [],
                          searchKbr: 'Prikaži sve'
                        })}>
                        <Dropdown.Toggle style={{width: '100%'}}>
                        { this.state.searchJLS === 'Prikaži sve' ? 'Odaberi jedinicu lokalne samouprave' : this.state.searchJLS }
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '100%'}}>
                          <Dropdown.Item eventKey={ 'Prikaži sve' } key={ -1 }>Prikaži sve</Dropdown.Item>
                          { this.state.renderJLS && this.state.opcijeJLS.length > 0 ? this.state.opcijeJLS.map((key, index) => <Dropdown.Item eventKey={ key.jls } key={ index }>{ key.jls }</Dropdown.Item>) : <Loader>LOADING</Loader> }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <Dropdown
                        style={{width: '100%'}}
                        onToggle={e => {
                          this.populateNaselje();
                          this.setState({renderNaselje: e})
                        }}
                        onSelect={ e => this.setState({ 
                          searchNaselje: e,
                          opcijeUlica: [],
                          searchUlica: 'Prikaži sve',
                          opcijeKbr: [],
                          searchKbr: 'Prikaži sve'
                        })}>
                        <Dropdown.Toggle style={{width: '100%'}}>
                        { this.state.searchNaselje === 'Prikaži sve' ? 'Odaberi naselje' : this.state.opcijeNaselje.filter(x => x.id === this.state.searchNaselje)[0].naselje }
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '100%'}}>
                          <Dropdown.Item eventKey={ 'Prikaži sve' } key={ -1 }>Prikaži sve</Dropdown.Item>
                          { this.state.renderNaselje && this.state.opcijeNaselje.length > 0 ? this.state.opcijeNaselje.filter( x => (this.state.searchJLS === 'Prikaži sve' || x.jls === this.state.searchJLS) ).map((key, index) => <Dropdown.Item eventKey={ key.id } key={ index }>{ key.naselje }</Dropdown.Item>) : <Loader>LOADING</Loader> }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <Dropdown
                        style={{width: '100%'}}
                        onToggle={e => {
                          this.populateUlica();
                          this.setState({renderUlica: e})
                        }}
                        onSelect={ e => this.setState({
                          searchUlica: e,
                          opcijeKbr: [],
                          searchKbr: 'Prikaži sve'
                        })}>
                        <Dropdown.Toggle style={{width: '100%'}} disabled={this.state.searchNaselje === 'Prikaži sve'}>
                        { this.state.searchUlica === 'Prikaži sve' ? 'Odaberi ulicu' : this.state.opcijeUlica.filter(x => x.id === this.state.searchUlica)[0].ulica }
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '100%'}}>
                          <Dropdown.Item eventKey={ 'Prikaži sve' } key={ -1 }>Prikaži sve</Dropdown.Item>
                          { this.state.renderUlica && this.state.opcijeUlica.length > 0 ? this.state.opcijeUlica.map((key, index) => <Dropdown.Item eventKey={ key.id } key={ index }>{ key.ulica }</Dropdown.Item>) : <Loader>LOADING</Loader> }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <Dropdown
                        style={{width: '100%'}}
                        onToggle={e => {
                          this.PopulateKbr();
                          this.setState({renderKbr: e})
                        }}
                        onSelect={ e => this.setState({ searchKbr: e }) }>
                        <Dropdown.Toggle disabled={ this.state.searchUlica === 'Prikaži sve' } style={{width: '100%'}}>
                        { this.state.searchKbr === 'Prikaži sve' ? 'Odaberi kućni broj' : this.state.opcijeKbr.filter(x => x.id === this.state.searchKbr)[0].kucni_broj }
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '100%'}}>
                          <Dropdown.Item eventKey={ 'Prikaži sve' } key={ -1 }>Prikaži sve</Dropdown.Item>
                          { this.state.renderKbr && this.state.opcijeKbr.length > 0 ? this.state.opcijeKbr.map((key, index) => <Dropdown.Item eventKey={ key.id } key={ index }>{ key.kucni_broj }</Dropdown.Item>) : <Loader>LOADING</Loader> }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputGroup>
                        <FormControl
                          type="text"
                          value={this.state.searchText}
                          onChange={e => {this.setState({ searchText: e.target.value })}}
                        />
                        <FormSelect
                          value={this.state.searchType}
                          onChange={e => this.setState({ searchType: e.target.value })}
                        >
                          <option value="Tsv">Ignoriraj redosljed riječi</option>
                          <option value="TsvPhrase">Matchiraj rečenicu</option>
                        </FormSelect>
                        <Button type="submit">
                          Traži
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            </Container>
            <Row>
              <Col width='900px'>
                { this.state.pages.length > 0 &&
                  <>
                    <div style={{height: '65vh'}}>
                      { Object.keys(this.state.searchResult).length > 0 && this.renderPage() }
                    </div>
                    <div style={{height: '5vh'}}>
                      { this.renderPageRow() }
                    </div>
                  </>
                }
              </Col>
              <Col>
                <div style={{width: '100%', height:'65vh'}}>
                  <OLMap
                    markers={ markers.length > 0 && markers }
                    mouseMapMove={ this.mouseMapMove }
                    url={ this.state.searchUrl }
                  />
                </div>
                { this.state.mapLocation !== null && <div>{this.state.mapLocation}</div> }
              </Col>
            </Row>
          </> 
        )}
        {this.state.isModalVisible && <CustomModal className='modal-630' greyedOut={this.state.isDocumentModalVisible} isModalVisible={this.state.isModalVisible} modalData={this.state.modalData} onHide={() => this.setState({isModalVisible: false})}/>}
        {this.state.isDocumentModalVisible && <CustomModal className='modal-630' isModalVisible={this.state.isDocumentModalVisible} modalData={this.state.documentModalData} onHide={() => this.setState({isDocumentModalVisible: false})}/>}
      </div>
    );
  }

}

export default Adresar;