import React, { Component } from 'react';
import Loader from "../components/Loader.js";
import Api from '../api.js'
import AsyncStorage from '@react-native-community/async-storage';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isAuthenticated: props.isAuthenticated,
    };
    this.handleLogout = props.handleLogout
    props.changeTitle('Anywhere Data');
  }

  async componentDidMount(){
      this.setState({isLoaded: true});
      const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
      if (userHasAuthenticated === 'true' && this.state.isAuthenticated) this.checkBackend();
  }

  checkBackend = async () => {
    console.log('checkBackend', this.state);
    const checkProtected = await Api.checkProtected();
    if (checkProtected.ok){
      console.log(checkProtected.data);
    }
    else if(checkProtected.data && checkProtected.data.message === 'TokenExpiredError: jwt expired'){
      alert('Your session has expired. Please log back in.');
      this.handleLogout();
    }
    else {
      alert('Unable to communicate to "Auth" Backend');
    }
  }

  render(){
    return (
      <div className="container page_container">
          { !this.state.isLoaded ? <Loader></Loader> : (
            <>
            <div></div>
          </> )}
      </div>
    );
  }

}

export default Home;