import styled from 'styled-components';

import * as set from '../../style/Variables/StyleSet';
import * as color from '../../style/Variables/Colors';
import * as font from '../../style/Variables/Fonts';

export const Table = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
    table, .table {
        width: 100%;
        margin-bottom: 15px;
        font-size: ${font.sFontSize};
        border: 1px solid ${color.greyMedium};
    }
    thead {
        border-top: 1px solid ${color.greyMedium};
        border-bottom: 1px solid ${color.greyMedium};
        tr, .tr {
            th, .th {
                font-weight: 500;
                padding: 7px;
                ${set.noWrap}
                border-right: 1px solid ${color.greyLight};
                &:last-child {
                    border-right: none;
                }
            }
        }
    }
    .table {
        border: 0.5px solid ${color.greyMedium};
    }
    .thead {
        border-bottom: 0.5px solid ${color.greyMedium};
        .tr {
            .th {
                font-weight: 500;
                padding: 7px;
                ${set.noWrap}
                border-right: 1px solid ${color.greyLight};
                &:last-child {
                    border-right: none;
                }
            }
        }
    }
    tbody, .tbody {
        tr, .tr {
            border-bottom: 1px solid ${color.greyLight};
            &:last-child {
                border-bottom: none;
            }
            &:hover {
                background: ${color.greyLight};
            }
            td, .td {
                padding: 7px;
                border-right: 1px solid ${color.greyLight};
                ${set.noWrap}

                &:last-child {
                    border-right: none;
                }
            }
            .expand_icon {
                font-size: ${font.lFontSize};
            }
            .status_box {
                span {
                    border-radius: 30px;
                    padding: 5px 10px;
                }
                .danger { background: ${color.danger}; color: ${color.white}; }
            }
            &:nth-child(even) td, &:nth-child(even) .td {
                background: ${color.greyLight};
            }
        }
    }
    .color_circle {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        &.primary {background: ${color.primaryColor};}
        &.secondary {background: ${color.secondaryColor};}
        &.success {background: ${color.success};}
        &.warning {background: ${color.warning};}
        &.danger {background: ${color.danger};}
        &.black {background: ${color.black};}
        &.grey {background: ${color.grey};}
        &.grey_medium {background: ${color.greyMedium};}
        &.grey_light {background: ${color.greyLight};}
        &.white {background: ${color.white};}
        &.brand {background: ${color.brand};}
        &.info {background: ${color.info};}
    }
    span.table_sort_icon {
        float: right;
    }
    .table_info {
        font-size: ${font.defaultFontSize};
        text-align: left;
    }
    &#filterTable {
        width: 100%;
        th, td {
            margin: 0;
            border-bottom: 1px solid ${color.greyMedium};
            border-right: 1px solid ${color.greyMedium};
      
            :last-child {
              border-right: 0;
            }
        }
        input[type=range] {
            -webkit-appearance: none;
            margin: 0 5px 0 0;
        }
        input[type=range]:focus {
            outline: none;
        }
        input[type=range]::-webkit-slider-runnable-track {
            height: 5px;
            cursor: pointer;
            box-shadow: none;
            background: ${color.greyLight};
            border-radius: 8px;
            border: none;
        }
        input[type=range]::-webkit-slider-thumb {
            box-shadow: none;
            border: none;
            height: 15px;
            width: 15px;
            border-radius: 100px;
            background: ${color.primaryColor};
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -5px;
        }
        input[type=range]::-moz-range-track {
            width: 100%;
            height: 5px;
            cursor: pointer;
            box-shadow: none;
            background: #3071a9;
            border: none;
            ${set.radius6px}
        }
        input[type=range]::-moz-range-thumb {
            box-shadow: none;
            border: none;
            height: 15px;
            width: 15px;
            border-radius: 100px;
            background: #ff0000;
            cursor: pointer;
        }
        input[type=range]::-ms-track {
            width: 100%;
            height: 5px;
            cursor: pointer;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }
        input[type=range]::-ms-fill-lower {
            background: #2a6495;
            border: none;
            box-shadow: none;
            ${set.radius6px}
        }
        input[type=range]::-ms-fill-upper {
            background: #3071a9;
            border: none;
            box-shadow: none;
            ${set.radius6px}
        }
        input[type=range]::-ms-thumb {
            box-shadow: none;
            border: none;
            height: 16px;
            width: 16px;
            border-radius: 100px;
            background: #ff0000;
            cursor: pointer;
        }
        input[type=range]:focus::-ms-fill-lower {
            background: #3071a9;
        }
        input[type=range]:focus::-ms-fill-upper {
            background: #367ebd;
        }
        button {
            margin-bottom: 0;
            height: 15px;
            font-size: ${font.mFontSize};
            line-height: 1.3;
        }
        .input_box {
            display: table-cell;
            text-align: left;
            vertical-align: middle;
            width: 100%;  
        }
        .btn_box {
            min-width: 30px;
            text-align: right;
            vertical-align: middle;
            display: table-cell;
            width: 30px; 
        }
        select {
            border: none;
            border-bottom: 1px solid ${color.greyLight};

            &:focus { outline: none !important; border-bottom: 1px solid ${color.primaryColor}; }
            &:hover { border-bottom: 1px solid ${color.primaryColor}; }

            option {
                background: ${color.white};
                color: ${color.black};
                border: none;
            }
            option:checked,
            option:hover {
                box-shadow: none;
                background-color: ${color.greyLight} !important;
            }
        }
        select:focus>option:checked {
            background-color: ${color.greyLight} !important;
        }
    }
    &#resizeTable {
        .resizer {
            right: -5px;
            background: ${color.primaryColor};
            width: 10px;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            touch-action:none;
    
            &.isResizing {
              background: ${color.secondaryColor};
            }
        }
    }
    .pagination {
        text-align: center;
        display: block;

        button {
            background: ${color.primaryColor};
            color: ${color.white};
            font-size: ${font.titleFontSize};
            width: 28px;
            height: 28px;
            text-align: center;
            padding: 2px;
            display: inline-block;
            cursor: pointer;
            ${set.radius100} 
        }
        button:disabled, button[disabled] {
            background: ${color.primaryColorLight};
            cursor: default;
        }
        input {
            text-align: center;
        }
        select {
            border: none;
            border-bottom: 1px solid ${color.greyLight};
            font-size: ${font.sFontSize};
            color: ${color.grey};
            font-size: ${font.sFontSize};
            color: #6f727d;
            height: 19px;
            display: block;
            width: 100%;

            &:focus { outline: none !important; border-bottom: 1px solid ${color.primaryColor}; }

            /* reset */
            margin: 0;      
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            
            background-image: linear-gradient(45deg,transparent 50%, ${color.greyMedium} 50%), linear-gradient(135deg, ${color.greyMedium} 50%,transparent 50%), linear-gradient(to right,#ccc,#ccc);
            background-position: calc(100% - 20px) 6px, calc(100% - 15px) 6px, calc(100% - 30px) -5px;
            background-size: 6px 5px, 5px 5px, 1px 20px;
            background-repeat: no-repeat;

            &:focus {
                background-image: linear-gradient(45deg, ${color.greyMedium} 50%, transparent 50%), linear-gradient(135deg, transparent 50%, ${color.greyMedium} 50%), linear-gradient(to right, #ccc, #ccc);
                background-position: calc(100% - 15px) 5px, calc(100% - 20px) 5px, calc(100% - 30px) -5px;
                background-size: 5px 6px,6px 6px, 1px 20px;
                background-repeat: no-repeat;
                outline: 0;
            }
            .styledSelect:active, .styledSelect.active {
                background-color:#eee;
            }
            .select_option {
                color: ${color.black};
                background: ${color.white};
            }
        }
    }
`;

