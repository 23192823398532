import React, { Component } from 'react';
import Loader from "../components/Loader.js";
import Api from '../api.js'
import AsyncStorage from '@react-native-community/async-storage';
import BasicTable from '../components/table/BasicTable';
import { Button, Modal, FormSelect, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaFile, FaPen, FaPlus, FaSearch, FaTrash, FaUser } from 'react-icons/fa';
import "./css/Admin.css";
import CustomModal from '../components/CustomModal.js';

class Admin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      users: [],
      isAuthenticated: props.isAuthenticated,
      isModalVisible: false,
      modalTitle: 'Add role',
      modalUuid: '',
      modalUserName: '',
      modalRole: '',
      modalType: '',
      modalData: '',
      isLogsModalVisible: false,
      logsModalTitle: '',
      logsModalData: null,
      logsModalClass: '',
      isDocumentModalVisible: false,
      documentModalTitle: '',
      documentModalData: null,
      documentModalClass: '',
    };
    this.handleLogout = props.handleLogout;
    props.changeTitle('Anywhere Data / Admin');
    this.userRightsTemplate = [
      {text: 'Admin', value: 'Admin', accountType: [{text: 'Admin', defaultData: null}], additionalData: null},
      {
        text: 'Gruntovnica',
        value: 'Gruntovnica',
        accountType: [
          {text: 'Demo', defaultData: { showAllData: false, showLimit: 50}}, 
          {text: 'Normal', defaultData: { showAllData: true, showLimit: 10000}}, 
          {text: 'Unlimited', defaultData: { showAllData: true, showLimit: -1}}
        ],
        additionalData: [
          {text: 'Show Limited Data', var: 'showAllData', type: 'boolean', options: ['Show All data', 'Show Limited Data']},
          {text: 'Document Limit (-1 for unlimited)', var: 'showLimit', type: 'number'}
        ]
      },
      {
        text: 'KatastarPL',
        value: 'KatastarPL',
        accountType: [
          {text: 'Demo', defaultData: { showAllData: false, showLimit: 50}}, 
          {text: 'Normal', defaultData: { showAllData: true, showLimit: 10000}}, 
          {text: 'Unlimited', defaultData: { showAllData: true, showLimit: -1}}
        ],
        additionalData: [
          {text: 'Show Limited Data', var: 'showAllData', type: 'boolean', options: ['Show All data', 'Show Limited Data']},
          {text: 'Document Limit (-1 for unlimited)', var: 'showLimit', type: 'number'}
        ]
      },
      {
        text: 'PolozeniUgovori',
        value: 'PolozeniUgovori',
        accountType: [
          {text: 'Demo', defaultData: { showAllData: false, showLimit: 50}}, 
          {text: 'Normal', defaultData: { showAllData: true, showLimit: 10000}}, 
          {text: 'Unlimited', defaultData: { showAllData: true, showLimit: -1}}
        ],
        additionalData: [
          {text: 'Show Limited Data', var: 'showAllData', type: 'boolean', options: ['Show All data', 'Show Limited Data']},
          {text: 'Document Limit (-1 for unlimited)', var: 'showLimit', type: 'number'}
        ]
      },
      {text: 'Adresar', value: 'Adresar', accountType:[{text: 'User', defaultData: null}], additionalData: null},
      {text: 'Imenik', value: 'Imenik', accountType:[
        {text: 'User', defaultData: { showNoCall: true, showHakom: true}},
        {text: 'Limited user', defaultData: { showNoCall: false, showHakom: false}}
      ],
      additionalData: [
        {text: 'Show No Call list', var: 'showNoCall', type: 'boolean', options: ['Show No Call list', 'Hide No Call list']},
        {text: 'Show Hakom', var: 'showHakom', type: 'boolean', options: ['Show Hakom', 'Hide Hakom']}
      ]},
      {text: 'Poslovni Imenik', value: 'PoslovniImenik', accountType:[
        {text: 'User', defaultData: { showNoCall: true, showHakom: true}},
        {text: 'Limited user', defaultData: { showNoCall: false, showHakom: false}}
      ], additionalData: [
        {text: 'Show No Call list', var: 'showNoCall', type: 'boolean', options: ['Show No Call list', 'Hide No Call list']},
        {text: 'Show Hakom', var: 'showHakom', type: 'boolean', options: ['Show Hakom', 'Hide Hakom']}
      ]},
      {text: 'Registracije', value: 'Registracije', accountType:[{text: 'User', defaultData: null}], additionalData: null},
      {text: 'Sudreg Objave', value: 'SudregObjave', accountType:[{text: 'User', defaultData: null}], additionalData: null},
      {text: 'Sudreg Api', value: 'SudregApi', accountType:[{text: 'User', defaultData: null}], additionalData: null},
      {text: 'Sud Objave', value: 'SudObjave', accountType:[{text: 'User', defaultData: null}], additionalData: null},
      {text: 'Facebook', value: 'Facebook', accountType:[{text: 'User', defaultData: null}], additionalData: null},
      {text: 'Obrtni Registar', value: 'ObrtniRegistar', accountType:[{text: 'User', defaultData: null}], additionalData: null},
      {text: 'Dozvole', value: 'Dozvole', accountType:[{text: 'User', defaultData: null}], additionalData: null},
      {text: 'Status', value: 'Status', accountType:[{text: 'User', defaultData: null}], additionalData: null},
      {text: 'CustomAddress', value: 'CustomAddress', accountType:[{text: 'User', defaultData: null}], additionalData: null},
    ]
  }

  async componentDidMount(){
    this.setState({isLoaded: true});
    const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
    if (userHasAuthenticated === 'true' && this.state.isAuthenticated){
      await this.checkBackend();
      const users = await Api.getUser();
      if (users.ok){
        this.setState({isLoaded: true, users: users.data.data});
      }
      else this.processError(users);
    }
  }

  processError = (data) => {
    console.log('processError', data);
    if(data && data.data && data.data.message === 'TokenExpiredError: jwt expired'){
      alert('Your session has expired. Please log back in.');
      this.handleLogout();
    }
    else if (data && data.data && data.data.message === 'ERROR' && data.data.data === 'ACCESS_DENIED'){
      alert('Nemate ovlasti pristupiti ovoj ruti. Molimo da se javite Anywhere podršci (support@anywhere.hr).');
    }
    else if (data && data.data && data.data.message && data.data.message.indexOf('request.body.text should NOT be shorter than 3 characters') > -1){
      alert('Molimo unesite barem 3 znaka.')
    }
    else if (data && data.data && data.data.message && data.data.message === 'ERROR'){
      alert(data.data.data);
    }
    else {
        alert('Unable to communicate to "Admin" Backend');
    }
  }

  addRole = (event) => {
    event.preventDefault();
    var value = event.target.value || event.target.parentElement.value || event.target.parentElement.parentElement.value;
    var user = this.state.users.filter(x => x.id === value.split('|')[0])[0];
    var module = value.split('|')[1];
    this.setState({
      isModalVisible: true,
      modalTitle: 'Add role',
      modalUuid: user.id,
      modalUserName: user.username,
      modalRole: module,
      modalType: '',
      modalData: '' 
    });
  }

  changeRole = (event) => {
    event.preventDefault();
    var value = event.target.value || event.target.parentElement.value || event.target.parentElement.parentElement.value;
    var user = this.state.users.filter(x => x.id === value.split('|')[0])[0];
    var module = user.UserRole.filter(x => x.module === value.split('|')[1])[0];
    this.setState({ 
      isModalVisible: true,
      modalTitle: 'Change role',
      modalUuid: user.id,
      modalUserName: user.username,
      modalRole: module.module,
      modalType: module.accountType,
      modalData: JSON.parse(module.roleData)
    });
  }

  deleteRole = async (event) => {
    event.preventDefault();
    var value = event.target.value || event.target.parentElement.value || event.target.parentElement.parentElement.value;
    var user = this.state.users.filter(x => x.id === value.split('|')[0])[0];
    var module = user.UserRole.filter(x => x.module === value.split('|')[1])[0];
    var result = window.confirm('Are you sure you wish to delete ' + module.module + ' from ' + user.username + '?');
    if (result){
      const response = await Api.removeUserRole({
        uuid: user.id,
        module: module.module
      })
      if (response.ok){
        var localUsers = this.state.users.map(user => user = (user.id === this.state.modalUuid) ? response.data.data : user)
        this.setState({ users: localUsers, isModalVisible: false });
      }
      else this.processError(response);
    }
  }

  saveRole = async (event) => {
    event.preventDefault();
    const data = {
      uuid: this.state.modalUuid,
      module: this.state.modalRole,
      accountType: this.state.modalType,
      roleData: JSON.stringify(this.state.modalData)
    }
    const response = await Api.addUserRole(data);
    if (response.ok){
      var localUsers = this.state.users.map(user => user = (user.id === this.state.modalUuid) ? response.data.data : user)
      this.setState({ users: localUsers, isModalVisible: false });
    }
    else this.processError(response)
  }

  getLogs = async (event) => {
    event.preventDefault();
    var value = event.target.value || event.target.parentElement.value || event.target.parentElement.parentElement.value;
    var user = value.split('|')[0];
    var module = value.split('|')[1];
    const logs = await Api.getLogs({uuid: user, module: module})
    if (logs.ok){
      if (module === 'Auth'){
        this.setState({ 
          isLogsModalVisible: true,
          logsModalClass: 'modal-1000',
          logsModalTitle: 'Authentication log',
          logsModalData: {
            type: 'prerender',
            data: <BasicTable
              columns={['Created At', 'ip', 'Client Id']}
              data={
                logs.data.data.sort((a, b) => (new Date(b.createdAt)) - (new Date(a.createdAt))).map(l => [
                  (new Date(l.createdAt)).toLocaleDateString('hr') + ' ' + (new Date(l.createdAt)).toLocaleTimeString('hr'),
                  JSON.parse(l.data)["x-real-ip"],
                  JSON.parse(l.data)["clientid"]
                ])
              }
            />
          }
        });
      }
      else if (module === 'Search'){
        this.setState({ 
          isLogsModalVisible: true,
          logsModalClass: 'modal-1200',
          logsModalTitle: 'Search history',
          logsModalData: {
            type: 'prerender',
            data: <BasicTable
              columns={['Created At', 'Module', 'Search Type', 'Search Text', 'Search Page', 'Search Params']}
              data={
                logs.data.data.sort((a, b) => (new Date(b.createdAt)) - (new Date(a.createdAt))).map(l => [
                  (new Date(l.createdAt)).toLocaleDateString('hr') + ' ' + (new Date(l.createdAt)).toLocaleTimeString('hr'),
                  l.module,
                  l.searchType,
                  <div style={{whiteSpace: 'normal'}}>{l.searchText}</div>,
                  l.searchPage,
                  <pre>{JSON.stringify(JSON.parse(l.searchParams), undefined, 2)}</pre>
                ])
              }
            />
          }
        });    
      }
      else if (module === 'Documents'){
        this.setState({ 
          isLogsModalVisible: true,
          logsModalClass: 'modal-1000',
          logsModalTitle: 'Document history',
          logsModalData: {
            type: 'prerender',
            data: <BasicTable
              columns={['Module', 'Created At', 'Document']}
              data={
                logs.data.data.sort((a, b) => (new Date(b.createdAt)) - (new Date(a.createdAt))).map(l => [
                  l.source,
                  (new Date(l.createdAt)).toLocaleDateString('hr') + ' ' + (new Date(l.createdAt)).toLocaleTimeString('hr'),
                  <Button onClick={() => this.showData(l.source, l.documentUuid)}>Document</Button>
                ])
              }
            />
          }
        });
      }
    }
    else this.processError(logs);
  }

  showData = async(source, id) => {
    // this.setState({isLoaded: false});
    console.log(source, id);
    if (source === 'Gruntovnica' || source === 'KatastarPL' || source === 'PolozeniUgovori'){
      var response = await Api[source].html(id);
      if (response.error){
        alert(response.message);
        this.setState({
          isLoaded: true
        });
      }
      else {
        this.setState({
          isLoaded: true,
          isDocumentModalVisible: true,
          documentModalClass: 'modal-630',
          documentModalTitle: source,
          documentModalData: {
            type: 'html',
            data: response.data.html
          }
        })
      }
    }
  }

  checkBackend = async () => {
    console.log('checkBackend', this.state);
    const checkProtected = await Api.checkProtected();
    if (checkProtected.ok){
      console.log(checkProtected.data);
    }
    else this.processError(checkProtected)
  }

  render(){
    var modalRenderData = [];
    if (this.state.isModalVisible){
      modalRenderData.push(['Username', this.state.modalUserName]);
      var module = this.userRightsTemplate.filter(right => right.value === this.state.modalRole).length > 0 ? this.userRightsTemplate.filter(right => right.value === this.state.modalRole)[0] : null;
      modalRenderData.push(['Module', module !== null ? module.text : this.state.modalRole]);
      modalRenderData.push(['Account Type', <FormSelect className='admin_select' value={this.state.modalType} onChange={e => this.setState({ modalType: e.target.value, modalData: module.accountType.filter(at => at.text === e.target.value).length > 0 ? module.accountType.filter(at => at.text === e.target.value)[0].defaultData : null})}>
        <option value="">Select Account Type</option>
        {module.accountType.map(at => <option value={at.text}>{at.text}</option>)}
      </FormSelect>]);
      if (module.additionalData !== null && this.state.modalType !== '') module.additionalData.forEach(ad => modalRenderData.push([ad.text, (
        ad.type === 'boolean' ? <FormSelect className='admin_select' value={this.state.modalData[ad.var]} onChange={e => {
            var modalData = this.state.modalData;
            modalData[ad.var] = e.target.value==='true' ? 'true' : e.target.value === 'false' ? false : null;
            this.setState({ modalData: modalData });
          }}>
            <option value="true">{ad.options[0]}</option>
            <option value="false">{ad.options[1]}</option>
          </FormSelect> : 
        ad.type === 'number' ? <FormControl
        type="number"
        className="admin_search"
        value={this.state.modalData[ad.var]}
        onChange={e => {
          var modalData = this.state.modalData;
          modalData[ad.var] = +e.target.value;
          this.setState({ modalData: modalData });
        }}
      /> : 
        ''
      )]));
      if (this.state.modalType !== '') modalRenderData.push(['COLSPAN', 2, <Button onClick={ this.saveRole }>Save</Button>]);
    }

    return (
      <div className="container page_container">
          { !this.state.isLoaded ? <Loader></Loader> : (
            <>
            <div>
              { Object.keys(this.state.users).length === 0 ? 'No users to administer' : <BasicTable
                columns={[
                  'User',
                  ...this.userRightsTemplate.map(right => <div className='d-flex align-items-center justify-content-center'>{right.text}</div>),
                  <div className='d-flex align-items-center justify-content-center'>Logovi</div>]}
                data={this.state.users.sort((a, b) => a.username.localeCompare(b.username)).map(
                  user => [<>{user.username}<br/>{user.email}</>, ...this.userRightsTemplate.map(
                    right => user.UserRole && user.UserRole.filter(role => role.module === right.value).length > 0 ? (
                        <div className='d-flex align-items-center justify-content-center'>
                          {right.value === 'Admin' && user.UserRole.filter(role => role.module === right.value)[0].accountType}
                          {right.value !== 'Admin' && <OverlayTrigger placement="right" overlay={<Tooltip style={{bgColor: 'white'}}>
                            accountType:
                            {user.UserRole.filter(role => role.module === right.value)[0].accountType}
                            {(user.UserRole.filter(role => role.module === right.value)[0].roleData !== 'null' && user.UserRole.filter(role => role.module === right.value)[0].roleData !== null ? (<>
                              {Object.keys(JSON.parse(user.UserRole.filter(role => role.module === right.value)[0].roleData)).map(key => <React.Fragment key={key}><br/>{key}: {JSON.stringify(JSON.parse(user.UserRole.filter(role => role.module === right.value)[0].roleData)[key])}</React.Fragment>)}
                            </>) : '')}
                          </Tooltip>}><Button value={user.id + '|' + right.value} onClick={this.changeRole}><FaPen /></Button></OverlayTrigger>}
                          {right.value !== 'Admin' && <Button value={user.id + '|' + right.value} onClick={this.deleteRole}><FaTrash /></Button>}
                        </div>
                      ) : right.value !== 'Admin' && <Button value={user.id + '|' + right.value} onClick={this.addRole}><FaPlus /></Button>
                    ),
                    <div className='d-flex align-items-center justify-content-center'>
                      <Button value={user.id + '|Auth'} onClick={this.getLogs}><FaUser /></Button>
                      <Button value={user.id + '|Search'} onClick={this.getLogs}><FaSearch /></Button>
                      <Button value={user.id + '|Documents'} onClick={this.getLogs}><FaFile /></Button>
                    </div>
                  ]
                )}
              />}
            </div>
            <Modal show={this.state.isModalVisible} onHide={() => this.setState({isModalVisible: false})}>
              <Modal.Title>
                { this.state.modalTitle }
              </Modal.Title>
              <Modal.Body>
              <BasicTable
                columns={['Name', 'Value']}
                data={modalRenderData}
              />
              </Modal.Body>
            </Modal>
            <CustomModal greyedOut={this.state.isDocumentModalVisible} className={this.state.logsModalClass} title={this.state.logsModalTitle} isModalVisible={this.state.isLogsModalVisible} modalData={this.state.logsModalData} onHide={() => this.setState({isLogsModalVisible: false})}/>
            <CustomModal className={this.state.documentModalClass} title={this.state.documentModalTitle} isModalVisible={this.state.isDocumentModalVisible} modalData={this.state.documentModalData} onHide={() => this.setState({isDocumentModalVisible: false})}/>
          </> )}
      </div>
    );
  }

}

export default Admin;