import React from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Gruntovnica from "./containers/Gruntovnica";
import KatastarPL from "./containers/KatastarPL";
import PolozeniUgovori from "./containers/PolozeniUgovori";
import Imenik from "./containers/Imenik";
import Admin from "./containers/Admin";
import Adresar from "./containers/Adresar";
import TemplateOld from "./containers/TemplateOld";
import Template from "./containers/Template";
import Status from "./containers/Status";


export default function Routes(props) {
  const location = useLocation();
  return (!props.isAuthenticated && location.pathname !== '/login' ? <Redirect to="/login" /> : 
  (
    <Switch>
        <Route exact path="/login">
          <Login isAuthenticated={ props.isAuthenticated } reloadApp={ props.reloadApp } passwordReset={ props.passwordReset } changeTitle={ props.changeTitle } />
        </Route>
        <Route exact path="/gruntovnica">
          <Gruntovnica isAuthenticated={ props.isAuthenticated } changeTitle={ props.changeTitle } />
        </Route>
        <Route exact path="/katastar_posjedovni_list">
          <KatastarPL isAuthenticated={ props.isAuthenticated } changeTitle={ props.changeTitle } />
        </Route>
        <Route exact path="/polozeni_ugovori">
          <PolozeniUgovori isAuthenticated={ props.isAuthenticated } changeTitle={ props.changeTitle } />
        </Route>
        <Route exact path="/adresar">
          <Adresar isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle } />
        </Route>
        <Route exact path="/imenik">
          <Imenik isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle } />
        </Route>
        <Route exact path="/registracije">
          <TemplateOld isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle }
            title="Registracije"
            columns={['naziv', 'mjesto', 'adresa', 'regoznaka', 'tip_vozila', 'osiguranje', 'kontakt']}
            modal1={['sektor', 'maticni_broj', 'godina_rodenja', 'ime', 'prezime', 'naziv', 'copcine', 'zupvlas', 'zupid', 'zupname', 'opcina', 'pttbr', 'mjesto', 'mjsif', 'adresa', 'regoznaka', 'cvrste', 'marka', 'tip', 'model', 'brsasije', 'obujam', 'snagakw', 'cvrmotora', 'masa', 'nosivost', 'katalizator', 'godinapr', 'putomjer', 'cvrstetp', 'regid', 'osig_ag', 'brpolice', 'osigdo', 'osigod', 'ndm', 'oib_vla', 'oib_lea', 'skad', 'oib_pom', 'ulica', 'kbr', 'vrsta_vozila', 'tip_vozila', 'tk_iznos', 'tk_mjera', 'opis_vozila', 'osiguranje', 'registracije_adresa', 'imenik_adresa', 'similarity_adresa', 'registracije_ime', 'imenik_ime', 'similarity_ime_i_prezime', 'kontakt', 'id', 'lokacija']}
          />
        </Route>
        <Route exact path="/registracije_raw">
          <TemplateOld isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle }
            title="Registracije Raw"
            columns={['ime', 'prezime', 'adresa', 'reg_oznaka', 'adresa_osiguranika']}
            modal1={['id', 'sasija', 'reg_oznaka', 'vlasnistvo', 'jmbg_mb', 'prezime', 'ime', 'vrsta_vozila', 'marka_vozila', 'tip_vozila', 'model_vozila', 'reg_oznaka_nova', 'vlasnistvo_novo', 'jmbg_mb_novi', 'prezime_novo', 'ime_novo', 'datum_odjave', 'mb_osig_drust', 'br_police', 'dat_poc_osig', 'dat_zav_osig', 'dat_azuriranja', 'oib', 'godina_proizvodnje', 'teh_kar', 'teh_kar1', 'godina_rodjenja', 'premijski_stupanj', 'adresa_osiguranika']}
          />
        </Route>
        <Route exact path="/sudreg_objave">
          <TemplateOld isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle }
            title="Sudreg Objave"
            columns={['Sud', 'Spis', 'MBS', 'OIB', 'Naziv', 'Status', 'TipOglasa', 'DatumObjave', 'TemaOglasa']}
            modal1={{type: 'html', column:'TekstObjave'}}
          />
        </Route>
        <Route exact path="/sudreg_api">
          <Template isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle }
            title="Sudreg Api"
            columns={['json_skracene_tvrtke_ime', 'json_sjedista_zupanija_naziv', 'json_sjedista_opcina_naziv', 'json_sjedista_naselje_naziv', 'json_sjedista_ulica', 'json_sjedista_kucni_broj', 'json_sjedista_kucni_podbroj']}
            modal1={['id', 'json_mbs', 'json_sud_id_nadlezan', 'json_sud_id_sluzba', 'json_oib', 'json_ino_podruznica', 'json_stecajna_masa', 'json_datum_osnivanja', 'json_tvrtke_ime', 'json_tvrtke_naznaka_imena', 'json_skracene_tvrtke_ime', 'emails', 'json_sjedista_zupanija_sifra', 'json_sjedista_zupanija_naziv', 'json_sjedista_opcina_sifra', 'json_sjedista_opcina_naziv', 'json_sjedista_naselje_sifra', 'json_sjedista_naselje_naziv', 'json_sjedista_naselje_van_sifrarnika', 'json_sjedista_ulica', 'json_sjedista_kucni_broj', 'json_sjedista_kucni_podbroj', 'json_sjedista_drzava_sifra', 'json_sjedista_drzava_naziv', 'json_sjedista_drzava_oznaka_2', 'json_sjedista_drzava_oznaka_3', 'json_temeljni_kapitali_valuta_drzava_sifra', 'json_temeljni_kapitali_valuta_drzava_naziv', 'json_temeljni_kapitali_valuta_drzava_oznaka_2', 'json_temeljni_kapitali_valuta_drzava_oznaka_3', 'json_temeljni_kapitali_valuta_sifra', 'json_temeljni_kapitali_valuta_naziv', 'json_temeljni_kapitali_iznos', 'json_predmeti_poslovanja', 'json_pravni_oblici_vrsta_pravnog_oblika_sifra', 'json_pravni_oblici_vrsta_pravnog_oblika_naziv', 'json_pravni_oblici_vrsta_pravnog_oblika_kratica', 'json_grupe_gfi', 'json_postupci_vrsta_postupak', 'json_postupci_vrsta_znacenje', 'json_postupci_datum_stjecaja', 'json_sud_nadlezan_id', 'json_sud_nadlezan_naselje_id', 'json_sud_nadlezan_sud_id_nadlezan', 'json_sud_nadlezan_izdaje_izvatke', 'json_sud_nadlezan_sifra', 'json_sud_nadlezan_naziv']}
            address={true}
          />
        </Route>
        <Route exact path="/sud_objave">
          <TemplateOld isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle }
            title="Sud Objave"
            columns={['vrstaObjave', 'sud', 'oznakaSpisa', 'naslov', 'sudionici', 'pocetniDanObjave']}
            modal1={['id', 'vrstaObjave', 'sud', 'oznakaSpisa', 'naslov', 'stecajniDuznik', 'adresaStecajnogDuznika', 'oibStecajnogDuznika', 'sudionici', 'pocetniDanObjave', 'posljednjiDanObjave', 'oglasLink', 'dokumentiDatoteke', 'dokumentiLink', 'ePredmetLink']}
            modal2={{type: 'html', column: 'html', title: 'objava'}}
            modal3={{type: 'text', column: 'dokumentText', title: 'dokument'}}
          />
        </Route>
        <Route exact path="/dozvole">
          <TemplateOld isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle }
            title="Dozvole"
            defaultColumn={'uuid'}
            columns={['Address', 'ApplicantName', 'Class', 'Urbr', 'DocumentName', 'CreationDate', 'StartDate', 'EndDate']}
            modal1={['uuid', 'Address', 'ApplicantName', 'ApplicationID', 'CadasterInfo', 'Class', 'CreationDate', 'DocumentContentGuid', 'DocumentName', 'Employee', 'EndDate', 'PismenoID', 'StartDate', 'Type', 'Urbr']}
            modal2={{type: 'text', column: 'tekst', title: 'dokument'}}
          />
        </Route>
        <Route exact path="/poslovni_imenik">
          <Template isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle }
          title="Poslovni Imenik"
            columns={[{type: 'link', title: 'Vanjski url', link: 'Poslovni imenik', column:'PoslovniImenikUrl', basepath: ''}, 'Name', 'Address', {type: 'date', title: 'Zadnja Provjera', column: 'updatedAt'}]}
            modal1={['id', 'PoslovniImenikUrl', 'Name', 'Address', 'updatedAt', 'Keyword', {type: 'contacts', column: 'CleanedContacts'}, 'Contacts']}
            address={true}
            contacts={"CleanedContacts"}
            nocall={true}
            hakom={true}
          />
        </Route>
        <Route exact path="/facebook">
          <TemplateOld isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle }
            title="Facebook"
            columns={[{type: 'link', title: 'Vanjski url', link: 'Facebook', column:'id', basepath: 'https://www.facebook.com/'}, 'phone', 'name', 'last_name', 'location', 'from', 'email', 'birthdate', 'timestamp']}
            modal1={['id', 'phone', 'name', 'last_name', 'gender', 'location', 'from', 'relationship_status', 'employer', 'email', 'birthdate', 'timestamp']}
          />
        </Route>
        <Route exact path="/obrtni_registar">
          <TemplateOld isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle }
            title="Obrtni Registar"
            columns={['MBO', 'Naziv', 'Stanje']}
            modal1={{type: 'html', column:'Html'}}
          />
        </Route>

        <Route exact path='/status'>
          <Status isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle } />
        </Route>
        <Route exact path='/admin'>
          <Admin isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle } />
        </Route>

        {/* Default. Leave at the end as it will match everything */}
        <Route>
          <Home isAuthenticated={ props.isAuthenticated } handleLogout={ props.handleLogout } changeTitle={ props.changeTitle } />
        </Route>
    </Switch>
  ))
}