import React, { Component } from 'react';
import Loader from "../components/Loader.js";
import Api from '../api.js'
import "./css/Template.css";
import { Button, FormGroup, FormControl, FormSelect, Container, Row, Col, Modal, InputGroup, Table } from "react-bootstrap";
import AsyncStorage from '@react-native-community/async-storage';
import BasicTable from '../components/table/BasicTable';
import CustomModal from '../components/CustomModal.js';

class Template extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isAuthenticated: props.isAuthenticated,
      searchText: '',
      searchType: 'Tsv',
      searchResult: {},
      page: 0,
      isModalVisible: false,
      modalData: null,
      modalNum: null,
      isAddressModalVisible: false,
      isDocumentModalVisible: false,
      addressModalData: null,
      documentModalData: null,
      pages: []
    };
  }

  async componentDidMount(){
    this.init();
  }

  async componentDidUpdate(prevProps){
    if(this.props.title !== prevProps.title){
      this.init();
    }
  }

  init = async () => {
    this.setState({
      isLoaded: false,
      isAuthenticated: this.props.isAuthenticated,
      searchText: '',
      searchType: 'Tsv',
      searchResult: {},
      page: 0,
      isModalVisible: false,
      modalData: null,
      pages: []
    });
    this.title = this.props.title;
    this.columns = this.props.columns;
    this.address = this.props.address;
    this.nocall = this.props.nocall;
    this.hakom = this.props.hakom;
    this.contacts = this.props.contacts;
    this.modal1 = this.props.modal1;
    this.modal2 = this.props.modal2;
    this.modal3 = this.props.modal3;
    this.modal4 = this.props.modal4;
    this.modal5 = this.props.modal5;
    this.handleLogout = this.props.handleLogout;
    this.searchTextPersist = '';
    this.searchTypePersist = 'Tsv';
    this.page = 1;
    this.props.changeTitle('Anywhere Data / ' + this.title);
    this.defaultColumn = this.props.defaultColumn || 'id'
    this.setState({isLoaded: true});
    const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
    if (userHasAuthenticated === 'true' && this.state.isAuthenticated) this.checkBackend();
  }

  countToPages = (count) => {
    var tempArray = []
    for (let i = 1; i <= Math.ceil(count/20); i++) {
      tempArray.push(i)
    }
    return tempArray;
  }

  checkBackend = async () => {
    const checkProtected = await Api[this.title.replaceAll(' ', '')].checkProtected();
    if (checkProtected.error) alert(checkProtected.message);
    else console.log(checkProtected);
  }

  search = (event) => {
    event.preventDefault();
    this.searchTextPersist = this.state.searchText;
    this.searchTypePersist = this.state.searchType;
    this.page = 1;
    this.setState({
      isLoaded: false
    });
    this.getSearchData();
  }

  searchPage = (event) => {
    event.preventDefault();
    this.page = +event.target.value;
    this.setState({
      isLoaded: false
    });
    this.getSearchData();
  }

  getSearchData = async () => {
    try {
      var options = {
        type: this.searchTypePersist,
        text: this.searchTextPersist,
        page: this.page
      };
      const searchResult = await Api[this.title.replaceAll(' ', '')].search(options);
      if (searchResult.error){
        this.setState({
          isLoaded: true
        });
        alert(searchResult.error);
      }
      else this.setState({
        page: this.page,
        pages: searchResult.count !== null ? this.countToPages(searchResult.count) : this.state.pages,
        searchResult: searchResult.data,
        isLoaded: true
      });
    } catch (error) {
      console.log(error);
    }
  }

  showModal = async (event) => {
    event.preventDefault();
    console.log(event.target.value);
    this.setState({
      isModalVisible: true,
      modalNum: event.target.value.substring(0, 1),
      modalData: this.state.searchResult.filter(x => '' + x[this.defaultColumn]===event.target.value.substring(2)).length > 0 ? this.state.searchResult.filter(x => '' + x[this.defaultColumn]===event.target.value.substring(2))[0] : null
    })
  }

  showAdresar = async (event) => {
    event.preventDefault();
    this.showAdresarData(event.target.value);
  }

  showAdresarData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isAddressModalVisible: true,
      addressModalData: null
    });
    var htmlData = await Api.Adresar.search({type: 'Uuid', text: uuid});
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isAddressModalVisible: false
      });
    }
    else {
      htmlData.data[0].gruntovnica=<div>{htmlData.data[0].Gruntovnica.map(x => <Button style={{marginTop: 5}} key={x.gruntovnicaUuid} value={x.gruntovnicaUuid} onClick={this.showGruntovnica}>Gruntovnica</Button>)}</div>
      htmlData.data[0].katastar_posjedovni_list=<div>{htmlData.data[0].KatastarPL.map(x => <Button style={{marginTop: 5}} key={x.katastarPLUuid} value={x.katastarPLUuid} onClick={this.showKatastarPL}>Vlasnički list</Button>)}</div>
      this.setState({
        addressModalData: {
          type: 'arrayWithMap',
          columns: ['zupanija', 'jls', 'naselje', 'ulica', 'kucni_broj', 'gruntovnica', 'katastar_posjedovni_list'],
          location: 'lokacija',
          data: htmlData.data[0]
        }
      })
    }
  }

  showKatastarPL = async (event) => {
    event.preventDefault();
    this.showKatastarPLHTMLData(event.target.value);
  }

  showKatastarPLHTMLData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isDocumentModalVisible: true,
      documentModalData: null
    });
    var htmlData = await Api.KatastarPL.html(uuid);
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isDocumentModalVisible: false
      });
    }
    else {
      this.setState({
        documentModalData: {
          type: 'html',
          data: htmlData.data.html
        }
      })
    }
  }

  showGruntovnica = async (event) => {
    event.preventDefault();
    this.showGruntovnicaHTMLData(event.target.value);
  }

  showGruntovnicaHTMLData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isDocumentModalVisible: true,
      documentModalData: null
    });
    var htmlData = await Api.Gruntovnica.html(uuid);
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isDocumentModalVisible: false
      });
    }
    else {
      this.setState({
        documentModalData: {
          type: 'html',
          data: htmlData.data.html
        }
      })
    }
  }

  renderPage = () => {
    var columns = [];
    if(this.modal1) columns.push(this.modal1.title ? this.modal1.title : 'Detalji');
    console.log(this);
    if(this.address) columns.push('Adresar');
    if(this.modal2) columns.push(this.modal2.title);
    if(this.modal3) columns.push(this.modal3.title);
    if(this.modal4) columns.push(this.modal4.title);
    if(this.modal5) columns.push(this.modal5.title);
    this.columns.forEach(x => columns.push(typeof(x) === 'string' ? x : x.title));
    if (this.contacts) columns.push('Kontakt');
    if (this.nocall && this.state.searchResult.length > 0 && this.state.searchResult[0].NoCall) columns.push('No Call lista');
    if (this.hakom && this.state.searchResult.length > 0  && this.state.searchResult[0].Hakom) columns.push('Hakom');
    const data = this.state.searchResult.map(singleItem => {
      let row = [];
      if(this.modal1) if (!this.modal1.type || ((this.modal1.type === 'html' || this.modal1.type  === 'text') && singleItem[this.modal1.column] !== null)) row.push(<Button value={'1_' + singleItem[this.defaultColumn]} onClick={this.showModal}>{this.modal1.title ? this.modal1.title : 'Prikaži'}</Button>); else row.push(<></>);
      if(this.address) row.push(singleItem.Adresar.map(x=> x.adresarUuid !==null ? x.adresarUuid.map(y => <Button key={y} style={{marginTop: 5}} value={y} onClick={this.showAdresar}>Adresar</Button>) : ''));
      if(this.modal2) if (!this.modal2.type || ((this.modal2.type === 'html' || this.modal2.type  === 'text') && singleItem[this.modal2.column] !== null)) row.push(<Button value={'2_' + singleItem[this.defaultColumn]} onClick={this.showModal}>{this.modal2.title}</Button>); else row.push(<></>);
      if(this.modal3) if (!this.modal3.type || ((this.modal3.type === 'html' || this.modal3.type  === 'text') && singleItem[this.modal3.column] !== null)) row.push(<Button value={'3_' + singleItem[this.defaultColumn]} onClick={this.showModal}>{this.modal3.title}</Button>); else row.push(<></>);
      if(this.modal4) if (!this.modal4.type || ((this.modal4.type === 'html' || this.modal4.type  === 'text') && singleItem[this.modal4.column] !== null)) row.push(<Button value={'4_' + singleItem[this.defaultColumn]} onClick={this.showModal}>{this.modal4.title}</Button>); else row.push(<></>);
      if(this.modal5) if (!this.modal5.type || ((this.modal5.type === 'html' || this.modal5.type  === 'text') && singleItem[this.modal5.column] !== null)) row.push(<Button value={'5_' + singleItem[this.defaultColumn]} onClick={this.showModal}>{this.modal5.title}</Button>); else row.push(<></>);
      this.columns.forEach(element => row.push(
      element.type && element.type==='date' ? (new Date(singleItem[element.column])).toLocaleDateString('hr') :
      element.type && element.type==='link' ? <a href={element.basepath + singleItem[element.column]} target="_blank" rel="noreferrer">{element.link}</a> :
      singleItem[element]));
      if (this.contacts) row.push(singleItem[this.contacts] !== null ? singleItem[this.contacts].split(', ').map(kontakt => <div>{kontakt}</div>) : null)
      if (this.nocall && this.state.searchResult.length > 0 && this.state.searchResult[0].NoCall) row.push(singleItem.NoCall && singleItem[this.contacts] !== null ? singleItem[this.contacts].split(', ').map(kontakt => <div>
        {singleItem.NoCall.filter(ncItem => ncItem.broj === kontakt).length>0 ? '🗸 (Od ' + (new Date(singleItem.NoCall.filter(ncItem => ncItem.broj === kontakt)[0].LastChangedDate)).toLocaleDateString('hr') + ')' : ''}
      </div>) : null);
      if (this.hakom && this.state.searchResult.length > 0  && this.state.searchResult[0].Hakom) row.push(singleItem.Hakom && singleItem[this.contacts] !== null ? singleItem[this.contacts].split(', ').map(kontakt => <div>
        {singleItem.Hakom && singleItem.Hakom.filter(hakomItem => hakomItem.upit === kontakt).length>0 ? (
          singleItem.Hakom.filter(hakomItem => hakomItem.upit === kontakt).sort((a, b) => (new Date(b.updatedAt)) - (new Date(a.updatedAt)))[0].operator + ' - ' + 
          singleItem.Hakom.filter(hakomItem => hakomItem.upit === kontakt).sort((a, b) => (new Date(b.updatedAt)) - (new Date(a.updatedAt)))[0].status + ' (' +
          (new Date(singleItem.Hakom.filter(hakomItem => hakomItem.upit === kontakt).sort((a, b) => (new Date(b.updatedAt)) - (new Date(a.updatedAt)))[0].updatedAt)).toLocaleDateString('hr') + ')'
          ) : <div>&nbsp;</div>}
      </div>) : null);
      return row;
    });

    return <BasicTable
      columns={columns}
      data={data}
    />
  }

  pageRange = (page, pageCount) => {
    var start = page - 2,
        end = page + 2;
    if (end > pageCount) {
        start -= (end - pageCount);
        end = pageCount;
    }
    if (start <= 0) {
        end += ((start - 1) * (-1));
        start = 1;
    }
    end = end > pageCount ? pageCount : end;
    return {
        start: start,
        end: end
    };
  }

  renderPageRow = () => {
    var pagesLocal = [];
    var range = this.pageRange(this.state.page, this.state.pages.length);
    var start = range.start;
    var end = range.end;
    if (start > 1 && this.state.pages.length > 5){
      pagesLocal.push(this.state.pages[0]);
    } else if (end < this.state.pages.length) end++;
    if (end === this.state.pages.length && start > 1) start --;
    for (var page_id = start; page_id <= end; page_id++) {
      pagesLocal.push(this.state.pages[page_id-1]);
    }
    
    if (end < this.state.pages.length){
      pagesLocal.push(this.state.pages[this.state.pages.length - 1]);
    }
    
    return (<Row>{ pagesLocal.map(page => <Button disabled={page === this.state.page} value={page} key={page} onClick={this.searchPage}>{page}</Button>)}</Row>)
  }

  render(){
    return (
      <div className="container page_container">
        { !this.state.isLoaded ? <Loader></Loader> : <>
          <div>
            <Container fluid>
              <form onSubmit={this.search} className="template_form">
                <FormGroup controlId="search">
                  <Row>
                    <Col>
                      <InputGroup>
                        <FormControl
                          type="text"
                          value={this.state.searchText}
                          onChange={e => {this.setState({ searchText: e.target.value })}}
                        />
                        <FormSelect
                          value={this.state.searchType}
                          onChange={e => this.setState({ searchType: e.target.value })}
                        >
                          <option value="Tsv">Ignoriraj redosljed riječi</option>
                          <option value="TsvPhrase">Matchiraj rečenicu</option>
                        </FormSelect>
                        <Button type="submit">
                          Traži
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            </Container>
            { this.state.pages.length > 0 &&
              <>
                <div>
                  { Object.keys(this.state.searchResult).length > 0 && this.renderPage() }
                </div>
                <div>
                  { this.renderPageRow() }
                </div>
              </>
            }
          </div>
        </> }
        <Modal dialogClassName="templateModal" show={this.state.isModalVisible} onHide={() => this.setState({isModalVisible: false})}>
          { Array.isArray(this['modal' + this.state.modalNum]) ? <Modal.Body>
            {this.state.modalData !== null && <Table style={{marginLeft: 20, marginRight: 20}}><tbody>{this['modal' + this.state.modalNum].map(col => typeof(col)==='object' ? col.type==='contacts' && this.state.modalData[col.column] !== null ? (
              this.state.modalData[col.column].split(', ').map(kontakt => <tr key = {col.column}>
                <td xs='2'>{kontakt}</td><td><Row><Col xs='12'>
                  {this.state.modalData.NoCall && this.state.modalData.NoCall.filter(ncItem => ncItem.broj === kontakt).length>0 ? <div>Na no Call listi od {(new Date(this.state.modalData.NoCall.filter(ncItem => ncItem.broj === kontakt)[0].LastChangedDate)).toLocaleDateString('hr')}</div> : ''}</Col></Row>
                  {this.state.modalData.Hakom && this.state.modalData.Hakom.filter(hakomItem => hakomItem.upit === kontakt).length>0 ? <>
                    {(this.state.modalData.Hakom.filter(hakomItem => hakomItem.upit === kontakt).sort((a, b) => (new Date(b.updatedAt)) - (new Date(a.updatedAt))).map(x => <>
                      <Row>
                        <Col xs='4'>{x.operator}</Col>
                        <Col xs='6'>{x.status}</Col>
                        <Col xs='2'>{(new Date(x.updatedAt)).toLocaleDateString('hr')}</Col>
                      </Row>
                    </>))}
                  </> : <>&nbsp;</>}
                </td>
              </tr>)
            ) : null : <tr key={col}><td>{col}:</td><td>{this.state.modalData[col]}</td></tr>)}</tbody></Table>}
          </Modal.Body> : (
            <>
              {this.state.isModalVisible && this['modal' + this.state.modalNum].type === 'html' && <Modal.Body dangerouslySetInnerHTML={{__html: (this.state.modalData[this['modal' + this.state.modalNum].column] !== null ? this.state.modalData[this['modal' + this.state.modalNum].column] : '').replace(/<a.*?a>/sg, '')}}/>}
              {this.state.isModalVisible && this['modal' + this.state.modalNum].type === 'text' && <Modal.Body><pre style={{whiteSpace: 'pre-wrap'}}>{this.state.modalData[this['modal' + this.state.modalNum].column] !== null ? this.state.modalData[this['modal' + this.state.modalNum].column] : ''}</pre></Modal.Body>}
            </>

          )}
        </Modal>
        {this.state.isAddressModalVisible && <CustomModal className='modal-630' isModalVisible={this.state.isAddressModalVisible} greyedOut={this.state.isDocumentModalVisible} modalData={this.state.addressModalData} onHide={() => this.setState({isAddressModalVisible: false})}/>}
        {this.state.isDocumentModalVisible && <CustomModal className='modal-630' isModalVisible={this.state.isDocumentModalVisible} modalData={this.state.documentModalData} onHide={() => this.setState({isDocumentModalVisible: false})}/>}
      </div>
    );  
  }

}

export default Template;