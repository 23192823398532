import React, { Component } from 'react';
import {fromLonLat, toLonLat} from 'ol/proj';
import { createEmpty, extend, getHeight, getWidth, boundingExtent, getCenter } from "ol/extent";
import 'ol/ol.css';
import {RMap, ROSM, RLayerCluster, RLayerVector, RFeature, RPopup} from 'rlayers';
import { RStyle, RIcon, RFill, RStroke, RRegularShape, RCircle, RText } from "rlayers/style";
import "./OLMap.css";
import { Point } from "ol/geom";
import GeoJSON from "ol/format/GeoJSON";
import locationIcon from "../img/location.svg";


const colorBlob = (size) => "rgba(" + [255, 153, 0, Math.min(0.8, 0.4 + Math.log(size / 10) / 20)].join() + ")";
const reader = new GeoJSON();
class OLMap extends Component {

  constructor(props) {
    super(props);
    // this.state = {
    //   allData: null,
    //   coords: null,
    // };
    this.data = null;
  }

  renderFeatureFunction(feature, resolution) {
    function extentFeatures(features, resolution) {
      const extent = createEmpty();
      for (const f of features) extend(extent, f.getGeometry().getExtent());
      return Math.round(0.25 * (getWidth(extent) + getHeight(extent))) / resolution;
    };

    const size = feature.get("features").length;
    if (size > 1) {
      const radius = extentFeatures(
        feature.get("features"),
        resolution
      );
      return (
        <React.Fragment>
          <RCircle radius={radius}>
            <RFill color={colorBlob(size)} />
          </RCircle>
          <RText text={size.toString()}>
            <RFill color="#fff" />
            <RStroke color="rgba(0, 0, 0, 0.6)" width={3} />
          </RText>
        </React.Fragment>
      );
    }

    // const unclusteredFeature = feature.get("features")[0];
    // console.log(unclusteredFeature);
    // return (
    //   <RFeature key={unclusteredFeature.id_} geometry={unclusteredFeature.values_.geometry} >
    //     <RStyle>
    //       <RIcon src={locationIcon} anchor={[0.5, 0.8]} />
    //     </RStyle>
    //     <RPopup trigger={"hover"} className="example-overlay">
    //       <div className="card">
    //         <div>{unclusteredFeature.values_.zupanija}, {unclusteredFeature.values_.jls}</div>
    //         <div>{unclusteredFeature.values_.naselje}, {unclusteredFeature.values_.ulica} {unclusteredFeature.values_.kucni_broj}</div>
    //       </div>
    //     </RPopup>
    //   </RFeature>
    // )
    return (
      <RRegularShape
        radius1={10}
        radius2={3}
        points={5}
        angle={Math.PI}
      >
        <RFill color="rgba(255, 153, 0, 0.8)" />
        <RStroke color="rgba(255, 204, 0, 0.2)" width={1} />
      </RRegularShape>
    );
  }

  renderMarkers(){
    const { markers } = this.props;
    const markersContainer = <RLayerVector zIndex={10}>
      { markers.map((marker, i) => (<RFeature key={marker.id} geometry={new Point(fromLonLat(marker.coords))}>
        <RStyle>
          <RIcon src={locationIcon} anchor={[0.5, 0.8]} />
        </RStyle>
        { marker.popup !== null && <RPopup trigger={"hover"} className="example-overlay">
            { marker.popup }
        </RPopup> }
      </RFeature>))}
    </RLayerVector>
    return markersContainer
  }

  findClosestPoint(coordinates){
    console.log(this.data, coordinates);
  }

  render(){
    const { markers, url, mouseMapMove } = this.props;
    const extent = boundingExtent([
      fromLonLat([12, 42]),
      fromLonLat([21, 46.7]),
    ]);
    const center = getCenter(extent);
    return(
      <RMap
        className="example-map"
        initial={{ center: center, zoom: 7 }}
        extent={extent}
        onPointerMove={x=> mouseMapMove(toLonLat(x.coordinate))}
      >
        <ROSM />
        { markers && this.renderMarkers() }
        {url !== null && <RLayerCluster
          distance={20}
          format={reader}
          url={url}
        >
          <RStyle
            render={this.renderFeatureFunction}
          />
        </RLayerCluster>}
      </RMap>
    );
  }

}

export default OLMap;
