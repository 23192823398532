import React, { Component } from 'react';
import Loader from "../components/Loader.js";
import Api from '../api.js'
import "./css/Template.css";
import { Button, FormGroup, FormControl, FormSelect, Container, Row, Col, Modal, InputGroup, Table } from "react-bootstrap";
import AsyncStorage from '@react-native-community/async-storage';
import BasicTable from '../components/table/BasicTable';

class TemplateOld extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isAuthenticated: props.isAuthenticated,
      searchText: '',
      searchType: 'Tsv',
      searchResult: {},
      page: 0,
      isModalVisible: false,
      modalData: null,
      modalNum: null,
      pages: []
    };
  }

  async componentDidMount(){
    this.init();
  }

  async componentDidUpdate(prevProps){
    if(this.props.title !== prevProps.title){
      this.init();
    }
  }

  init = async () => {
    this.setState({
      isLoaded: false,
      isAuthenticated: this.props.isAuthenticated,
      searchText: '',
      searchType: 'Tsv',
      searchResult: {},
      page: 0,
      isModalVisible: false,
      modalData: null,
      pages: []
    });
    this.title = this.props.title;
    this.columns = this.props.columns;
    this.modal1 = this.props.modal1;
    this.modal2 = this.props.modal2;
    this.modal3 = this.props.modal3;
    this.modal4 = this.props.modal4;
    this.modal5 = this.props.modal5;
    this.handleLogout = this.props.handleLogout;
    this.searchTextPersist = '';
    this.searchTypePersist = 'Tsv';
    this.page = 1;
    this.props.changeTitle('Anywhere Data / ' + this.title);
    this.defaultColumn = this.props.defaultColumn || 'id'
    this.setState({isLoaded: true});
    const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
    if (userHasAuthenticated === 'true' && this.state.isAuthenticated) this.checkBackend();
  }

  countToPages = (count) => {
    var tempArray = []
    for (let i = 1; i <= Math.ceil(count/20); i++) {
      tempArray.push(i)
    }
    return tempArray;
  }

  processError = (data) => {
    console.log('processError', data);
    if(data && data.data && data.data.message === 'TokenExpiredError: jwt expired'){
      alert('Your session has expired. Please log back in.');
      this.handleLogout();
    }
    else if (data && data.data && data.data.message === 'ERROR' && data.data.data === 'ACCESS_DENIED'){
      alert('Nemate ovlasti pristupiti ovoj ruti. Molimo da se javite Anywhere podršci (support@anywhere.hr).');
    }
    else if (data && data.data && data.data.message && data.data.message.indexOf('request.body.text should NOT be shorter than 3 characters') > -1){
      alert('Molimo unesite barem 3 znaka.')
    }
    else if (data && data.data && data.data.message && data.data.message === 'ERROR'){
      alert(data.data.data);
    }
    else {
        alert('Unable to communicate to "' + this.title + '" Backend');
    }
  }

  checkBackend = async () => {
    const checkProtected = await Api[this.title.replaceAll(' ', '')].checkProtected();
    if (checkProtected.ok){
      console.log(checkProtected.data);
    }
    else this.processError(checkProtected);
  }

  search = (event) => {
    event.preventDefault();
    this.searchTextPersist = this.state.searchText;
    this.searchTypePersist = this.state.searchType;
    this.page = 1;
    this.setState({
      isLoaded: false
    });
    this.getSearchData();
  }

  searchPage = (event) => {
    event.preventDefault();
    this.page = +event.target.value;
    this.setState({
      isLoaded: false
    });
    this.getSearchData();
  }

  getSearchData = async () => {
    try {
      var options = {
        type: this.searchTypePersist,
        text: this.searchTextPersist,
        page: this.page
      };
      const searchResult = await Api[this.title.replaceAll(' ', '')].search(options);
      if (searchResult.ok){
        this.setState({
          page: this.page,
          pages: searchResult.data.count !== null ? this.countToPages(searchResult.data.count) : this.state.pages,
          searchResult: searchResult.data.data,
          isLoaded: true
        });
      }
      else {
        this.processError(searchResult);
        this.setState({
          isLoaded: true
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  showModal = async (event) => {
    event.preventDefault();
    console.log(event.target.value);
    this.setState({
      isModalVisible: true,
      modalNum: event.target.value.substring(0, 1),
      modalData: this.state.searchResult.filter(x => '' + x[this.defaultColumn]===event.target.value.substring(2)).length > 0 ? this.state.searchResult.filter(x => '' + x[this.defaultColumn]===event.target.value.substring(2))[0] : null
    })
  }

  renderPage = () => {
    var columns = [];
    if(this.modal1) columns.push(this.modal1.title ? this.modal1.title : 'Detalji');
    if(this.modal2) columns.push(this.modal2.title);
    if(this.modal3) columns.push(this.modal3.title);
    if(this.modal4) columns.push(this.modal4.title);
    if(this.modal5) columns.push(this.modal5.title);
    this.columns.forEach(x => columns.push(typeof(x) === 'string' ? x : x.title));
    const data = this.state.searchResult.map(singleItem => {
      let row = [];
      if(this.modal1) if (!this.modal1.type || ((this.modal1.type === 'html' || this.modal1.type  === 'text') && singleItem[this.modal1.column] !== null)) row.push(<Button value={'1_' + singleItem[this.defaultColumn]} onClick={this.showModal}>{this.modal1.title ? this.modal1.title : 'Prikaži'}</Button>); else row.push(<></>);
      if(this.modal2) if (!this.modal2.type || ((this.modal2.type === 'html' || this.modal2.type  === 'text') && singleItem[this.modal2.column] !== null)) row.push(<Button value={'2_' + singleItem[this.defaultColumn]} onClick={this.showModal}>{this.modal2.title}</Button>); else row.push(<></>);
      if(this.modal3) if (!this.modal3.type || ((this.modal3.type === 'html' || this.modal3.type  === 'text') && singleItem[this.modal3.column] !== null)) row.push(<Button value={'3_' + singleItem[this.defaultColumn]} onClick={this.showModal}>{this.modal3.title}</Button>); else row.push(<></>);
      if(this.modal4) if (!this.modal4.type || ((this.modal4.type === 'html' || this.modal4.type  === 'text') && singleItem[this.modal4.column] !== null)) row.push(<Button value={'4_' + singleItem[this.defaultColumn]} onClick={this.showModal}>{this.modal4.title}</Button>); else row.push(<></>);
      if(this.modal5) if (!this.modal5.type || ((this.modal5.type === 'html' || this.modal5.type  === 'text') && singleItem[this.modal5.column] !== null)) row.push(<Button value={'5_' + singleItem[this.defaultColumn]} onClick={this.showModal}>{this.modal5.title}</Button>); else row.push(<></>);
        this.columns.forEach(element => row.push(
        element.type && element.type==='date' ? (new Date(singleItem[element.column])).toLocaleDateString('hr') :
        element.type && element.type==='link' ? <a href={element.basepath + singleItem[element.column]} target="_blank" rel="noreferrer">{element.link}</a> :
        singleItem[element]));
      return row;
    });

    return <BasicTable
      columns={columns}
      data={data}
    />
  }

  pageRange = (page, pageCount) => {
    var start = page - 2,
        end = page + 2;
    if (end > pageCount) {
        start -= (end - pageCount);
        end = pageCount;
    }
    if (start <= 0) {
        end += ((start - 1) * (-1));
        start = 1;
    }
    end = end > pageCount ? pageCount : end;
    return {
        start: start,
        end: end
    };
  }

  renderPageRow = () => {
    var pagesLocal = [];
    var range = this.pageRange(this.state.page, this.state.pages.length);
    var start = range.start;
    var end = range.end;
    if (start > 1 && this.state.pages.length > 5){
      pagesLocal.push(this.state.pages[0]);
    } else if (end < this.state.pages.length) end++;
    if (end === this.state.pages.length && start > 1) start --;
    for (var page_id = start; page_id <= end; page_id++) {
      pagesLocal.push(this.state.pages[page_id-1]);
    }
    
    if (end < this.state.pages.length){
      pagesLocal.push(this.state.pages[this.state.pages.length - 1]);
    }
    
    return (<Row>{ pagesLocal.map(page => <Button disabled={page === this.state.page} value={page} key={page} onClick={this.searchPage}>{page}</Button>)}</Row>)
  }

  render(){
    return (
      <div className="container page_container">
        { !this.state.isLoaded ? <Loader></Loader> : <>
          <div>
            <Container fluid>
              <form onSubmit={this.search} className="template_form">
                <FormGroup controlId="search">
                  <Row>
                    <Col>
                      <InputGroup>
                        <FormControl
                          type="text"
                          value={this.state.searchText}
                          onChange={e => {this.setState({ searchText: e.target.value })}}
                        />
                        <FormSelect
                          value={this.state.searchType}
                          onChange={e => this.setState({ searchType: e.target.value })}
                        >
                          <option value="Tsv">Ignoriraj redosljed riječi</option>
                          <option value="TsvPhrase">Matchiraj rečenicu</option>
                        </FormSelect>
                        <Button type="submit">
                          Traži
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            </Container>
            { this.state.pages.length > 0 &&
              <>
                <div>
                  { Object.keys(this.state.searchResult).length > 0 && this.renderPage() }
                </div>
                <div>
                  { this.renderPageRow() }
                </div>
              </>
            }
          </div>
        </> }
        <Modal dialogClassName="templateModal" show={this.state.isModalVisible} onHide={() => this.setState({isModalVisible: false})}>
          { Array.isArray(this['modal' + this.state.modalNum]) ? <Modal.Body>
            {this.state.modalData !== null && <Table style={{marginLeft: 20, marginRight: 20}}><tbody>{this['modal' + this.state.modalNum].map(col => <tr key={col}><td>{col}:</td><td>{this.state.modalData[col]}</td></tr>)}</tbody></Table>}
          </Modal.Body> : (
            <>
              {this.state.isModalVisible && this['modal' + this.state.modalNum].type === 'html' && <Modal.Body dangerouslySetInnerHTML={{__html: (this.state.modalData[this['modal' + this.state.modalNum].column] !== null ? this.state.modalData[this['modal' + this.state.modalNum].column] : '').replace(/<a.*?a>/sg, '')}}/>}
              {this.state.isModalVisible && this['modal' + this.state.modalNum].type === 'text' && <Modal.Body><pre style={{whiteSpace: 'pre-wrap'}}>{this.state.modalData[this['modal' + this.state.modalNum].column] !== null ? this.state.modalData[this['modal' + this.state.modalNum].column] : ''}</pre></Modal.Body>}
            </>

          )}
        </Modal>
      </div>
    );  
  }

}

export default TemplateOld;