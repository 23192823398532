import React, { Component } from 'react';
import Loader from "../components/Loader.js";
import Api from '../api.js'
import "./css/Imenik.css";
import { Button, FormGroup, FormControl, FormSelect, Container, Row, Col, Modal, InputGroup, Table } from "react-bootstrap";
import AsyncStorage from '@react-native-community/async-storage';
import BasicTable from '../components/table/BasicTable';
import CustomModal from '../components/CustomModal.js';

class Imenik extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isAuthenticated: props.isAuthenticated,
      searchText: '',
      searchType: 'Tsv',
      searchResult: {},
      page: 0,
      isModalVisible: false,
      isAddressModalVisible: false,
      isDocumentModalVisible: false,
      modalData: null,
      addressModalData: null,
      documentModalData: null,
      pages: []
    };
    this.handleLogout = props.handleLogout
    this.searchTextPersist = '';
    this.searchTypePersist = 'Tsv';
    this.page = 1;
    props.changeTitle('Anywhere Data / Imenik');
  }

  async componentDidMount(){
      this.setState({isLoaded: true});
      const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
      if (userHasAuthenticated === 'true' && this.state.isAuthenticated) this.checkBackend();
  }

  countToPages = (count) => {
    var tempArray = []
    for (let i = 1; i <= Math.ceil(count/20); i++) {
      tempArray.push(i)
    }
    return tempArray;
  }

  checkBackend = async () => {
    const checkProtected = await Api.Imenik.checkProtected();
    if (checkProtected.error) alert(checkProtected.message);
    else console.log(checkProtected);
  }

  search = (event) => {
    event.preventDefault();
    this.searchTextPersist = this.state.searchText;
    this.searchTypePersist = this.state.searchType;
    this.page = 1;
    this.setState({
      isLoaded: false
    });
    this.getSearchData();
  }

  searchPage = (event) => {
    event.preventDefault();
    this.page = +event.target.value;
    this.setState({
      isLoaded: false
    });
    this.getSearchData();
  }

  getSearchData = async () => {
    try {
      var options = {
        type: this.searchTypePersist,
        text: this.searchTextPersist,
        page: this.page
      };
      const searchResult = await Api.Imenik.search(options);
      if (searchResult.error){
        this.setState({
          isLoaded: true
        });
        alert(searchResult.error);
      }
      else this.setState({
        page: this.page,
        pages: searchResult.count !== null ? this.countToPages(searchResult.count) : this.state.pages,
        searchResult: searchResult.data,
        isLoaded: true
      });
    } catch (error) {
      console.log(error);
    }
  }

  showAdresar = async (event) => {
    event.preventDefault();
    this.showAdresarData(event.target.value);
  }

  showAdresarData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isAddressModalVisible: true,
      addressModalData: null
    });
    var htmlData = await Api.Adresar.search({type: 'Uuid', text: uuid});
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isAddressModalVisible: false
      });
    }
    else {
      htmlData.data[0].gruntovnica=<div>{htmlData.data[0].Gruntovnica.map(x => <Button style={{marginTop: 5}} key={x.gruntovnicaUuid} value={x.gruntovnicaUuid} onClick={this.showGruntovnica}>Gruntovnica</Button>)}</div>
      htmlData.data[0].katastar_posjedovni_list=<div>{htmlData.data[0].KatastarPL.map(x => <Button style={{marginTop: 5}} key={x.katastarPLUuid} value={x.katastarPLUuid} onClick={this.showKatastarPL}>Vlasnički list</Button>)}</div>
      this.setState({
        addressModalData: {
          type: 'arrayWithMap',
          columns: ['zupanija', 'jls', 'naselje', 'ulica', 'kucni_broj', 'gruntovnica', 'katastar_posjedovni_list'],
          location: 'lokacija',
          data: htmlData.data[0]
        }
      })
    }
  }

  showKatastarPL = async (event) => {
    event.preventDefault();
    this.showKatastarPLHTMLData(event.target.value);
  }

  showKatastarPLHTMLData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isDocumentModalVisible: true,
      documentModalData: null
    });
    var htmlData = await Api.KatastarPL.html(uuid);
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isDocumentModalVisible: false
      });
    }
    else {
      this.setState({
        documentModalData: {
          type: 'html',
          data: htmlData.data.html
        }
      })
    }
  }

  showGruntovnica = async (event) => {
    event.preventDefault();
    this.showGruntovnicaHTMLData(event.target.value);
  }

  showGruntovnicaHTMLData = async (uuid) => {
    console.log(uuid);
    this.setState({
      isDocumentModalVisible: true,
      documentModalData: null
    });
    var htmlData = await Api.Gruntovnica.html(uuid);
    if (htmlData.error){
      alert(htmlData.message);
      this.setState({
        isDocumentModalVisible: false
      });
    }
    else {
      this.setState({
        documentModalData: {
          type: 'html',
          data: htmlData.data.html
        }
      })
    }
  }

  showModal = async (event) => {
    event.preventDefault();
    console.log('showModal', event.target.value);
    this.setState({
      isModalVisible: true,
      modalData: this.state.searchResult.filter(x => x.uuid===event.target.value).length > 0 ? this.state.searchResult.filter(x => x.uuid===event.target.value)[0] : null
    })
  }

  renderPage = () => {
    const columns = [ 'Vanjski url', 'Detalji', 'Adresar', 'Ime', 'Prezime', 'Adresa', 'Broj', this.state.searchResult[0].NoCall ? 'No Call lista' : null, this.state.searchResult[0].Hakom ? 'Hakom' : null, 'Obrisano' ].filter(x=>x!==null);
    const data = this.state.searchResult.map(singleItem => [
      <a href={'http://imenik.hr' + singleItem.url} target="_blank" rel="noreferrer">imenik</a>,
      <Button value={singleItem.uuid} onClick={this.showModal}>Prikaži</Button>,
      singleItem.Adresar.map(x=> x.adresarUuid !==null ? x.adresarUuid.map(y => <Button key={y} style={{marginTop: 5}} value={y} onClick={this.showAdresar}>Adresar</Button>) : ''),
      singleItem.ime,
      singleItem.prezime,
      singleItem.adresa,
      singleItem.kontakt.split(', ').map(kontakt => <div>
        {kontakt}
      </div>),
      singleItem.NoCall ? singleItem.kontakt.split(', ').map(kontakt => <div>
        {singleItem.NoCall.filter(ncItem => ncItem.broj === kontakt).length>0 ? '🗸 (Od ' + (new Date(singleItem.NoCall.filter(ncItem => ncItem.broj === kontakt)[0].LastChangedDate)).toLocaleDateString('hr') + ')' : ''}
      </div>) : null,
      singleItem.Hakom ? singleItem.kontakt.split(', ').map(kontakt => <div>
        {singleItem.Hakom && singleItem.Hakom.filter(hakomItem => hakomItem.upit === kontakt).length>0 ? (
          singleItem.Hakom.filter(hakomItem => hakomItem.upit === kontakt).sort((a, b) => (new Date(b.updatedAt)) - (new Date(a.updatedAt)))[0].operator + ' - ' + 
          singleItem.Hakom.filter(hakomItem => hakomItem.upit === kontakt).sort((a, b) => (new Date(b.updatedAt)) - (new Date(a.updatedAt)))[0].status + ' (' +
          (new Date(singleItem.Hakom.filter(hakomItem => hakomItem.upit === kontakt).sort((a, b) => (new Date(b.updatedAt)) - (new Date(a.updatedAt)))[0].updatedAt)).toLocaleDateString('hr') + ')'
          ) : <div>&nbsp;</div>}
      </div>) : null,
      singleItem.deleted ? '🗸' : ''
    ].filter(x=>x!==null));

    return <BasicTable
      columns={columns}
      data={data}
    />
  }

  pageRange = (page, pageCount) => {
    var start = page - 2,
        end = page + 2;
    if (end > pageCount) {
        start -= (end - pageCount);
        end = pageCount;
    }
    if (start <= 0) {
        end += ((start - 1) * (-1));
        start = 1;
    }
    end = end > pageCount ? pageCount : end;
    return {
        start: start,
        end: end
    };
  }

  renderPageRow = () => {
    var pagesLocal = [];
    var range = this.pageRange(this.state.page, this.state.pages.length);
    var start = range.start;
    var end = range.end;
    if (start > 1 && this.state.pages.length > 5){
      pagesLocal.push(this.state.pages[0]);
    } else if (end < this.state.pages.length) end++;
    if (end === this.state.pages.length && start > 1) start --;
    for (var page_id = start; page_id <= end; page_id++) {
      pagesLocal.push(this.state.pages[page_id-1]);
    }
    
    if (end < this.state.pages.length){
      pagesLocal.push(this.state.pages[this.state.pages.length - 1]);
    }
    
    return (<Row>{ pagesLocal.map(page => <Button disabled={page === this.state.page} value={page} key={page} onClick={this.searchPage}>{page}</Button>)}</Row>)
  }

  render(){
    return (
      <div className="container page_container">
        { !this.state.isLoaded ? <Loader></Loader> : <>
          <div>
            <Container fluid>
              <form onSubmit={this.search} className="imenik_form">
                <FormGroup controlId="search">
                  <Row>
                    <Col>
                      <InputGroup>
                        <FormControl
                          type="text"
                          value={this.state.searchText}
                          onChange={e => {this.setState({ searchText: e.target.value })}}
                        />
                        <FormSelect
                          value={this.state.searchType}
                          onChange={e => this.setState({ searchType: e.target.value })}
                        >
                          <option value="Tsv">Ignoriraj redosljed riječi</option>
                          <option value="TsvPhrase">Matchiraj rečenicu</option>
                        </FormSelect>
                        <Button type="submit">
                          Traži
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            </Container>
            { this.state.pages.length > 0 &&
              <>
                <div>
                  { Object.keys(this.state.searchResult).length > 0 && this.renderPage() }
                </div>
                <div>
                  { this.renderPageRow() }
                </div>
              </>
            }
          </div>
        </> }
        <Modal dialogClassName="imenikModal" show={this.state.isModalVisible} onHide={() => this.setState({isModalVisible: false})}>
          <Modal.Body>
            {this.state.modalData !== null && <Table style={{marginLeft: 20, marginRight: 20}}>
              <Row>
                <Col>
                  Vanjski url:
                </Col>
                <Col>
                  <a href={'http://imenik.hr' + this.state.modalData.url} target="_blank" rel="noreferrer">imenik</a>
                </Col>
              </Row>
              <Row>
                <Col>
                  Ime i prezime:
                </Col>
                <Col>
                  {this.state.modalData.ime} {this.state.modalData.prezime}
                </Col>
              </Row>
              <Row>
                <Col>
                  Adresa:
                </Col>
                <Col>
                  {this.state.modalData.adresa}
                </Col>
              </Row>
              <Row>
                <Col>
                  Obrisano iz imenika:
                </Col>
                <Col>
                  {this.state.modalData.deleted ? '🗸' : ''}
                </Col>
              </Row>
              <Row>
                <Col>
                  Detektirano u imeniku:
                </Col>
                <Col>
                  {(new Date(this.state.modalData.createdAt)).toLocaleDateString('hr')}
                </Col>
              </Row>
              <Row>
                <Col>
                  Datum zadnje provjere (Zaustavlja se kod detekcije obrisanog):
                </Col>
                <Col>
                  {(new Date(this.state.modalData.updatedAt)).toLocaleDateString('hr')}
                </Col>
              </Row>
              {this.state.modalData.deleted && (new Date(this.state.modalData.lastCheckedAt)).toLocaleDateString('hr') !== (new Date(this.state.modalData.updatedAt)).toLocaleDateString('hr') && (
                <Row>
                  <Col>
                    Datum zadnje uspješne provjere:
                  </Col>
                  <Col>
                    {this.state.modalData.deleted && (new Date(this.state.modalData.lastCheckedAt)).toLocaleDateString('hr')}
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  Kontakti:
                </Col>
              </Row>
              {this.state.modalData.kontakt.split(', ').map(kontakt => <Row>
                <Col xs='2'>{kontakt}</Col>
                <Col>
                
                <Row><Col xs='12'>{this.state.modalData.NoCall && this.state.modalData.NoCall.filter(ncItem => ncItem.broj === kontakt).length>0 ? <div>Na no Call listi od {(new Date(this.state.modalData.NoCall.filter(ncItem => ncItem.broj === kontakt)[0].LastChangedDate)).toLocaleDateString('hr')}</div> : ''}</Col></Row>
                
                
                {this.state.modalData.Hakom && this.state.modalData.Hakom.filter(hakomItem => hakomItem.upit === kontakt).length>0 ? <>
                  {(this.state.modalData.Hakom.filter(hakomItem => hakomItem.upit === kontakt).sort((a, b) => (new Date(b.updatedAt)) - (new Date(a.updatedAt))).map(x => <>
                    <Row>
                      <Col xs='4'>{x.operator}</Col>
                      <Col xs='6'>{x.status}</Col>
                      <Col xs='2'>{(new Date(x.updatedAt)).toLocaleDateString('hr')}</Col>
                    </Row>
                  </>))}
                </> : <>&nbsp;</>}
                </Col>
              </Row>)}
            </Table>}
          </Modal.Body>
        </Modal>
        {this.state.isAddressModalVisible && <CustomModal className='modal-630' isModalVisible={this.state.isAddressModalVisible} greyedOut={this.state.isDocumentModalVisible} modalData={this.state.addressModalData} onHide={() => this.setState({isAddressModalVisible: false})}/>}
        {this.state.isDocumentModalVisible && <CustomModal className='modal-630' isModalVisible={this.state.isDocumentModalVisible} modalData={this.state.documentModalData} onHide={() => this.setState({isDocumentModalVisible: false})}/>}
      </div>
    );  
  }

}

export default Imenik;