import React, { Component } from 'react';
import Loader from "../components/Loader.js";
import Api from '../api.js'
import "./css/Template.css";
import { Container } from "react-bootstrap";
import AsyncStorage from '@react-native-community/async-storage';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
class Status extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isAuthenticated: props.isAuthenticated,
      data: {},
      dataWithGraph: {},
      dataWithoutGraph: []
    };
  }

  async componentDidMount(){
    this.init();
  }

  async componentDidUpdate(prevProps){
    if(this.props.title !== prevProps.title){
      this.init();
    }
  }

  init = async () => {
    this.setState({
      isLoaded: false,
      isAuthenticated: this.props.isAuthenticated,
    });
    this.handleLogout = this.props.handleLogout;
    this.props.changeTitle('Anywhere Data / Status');
    this.setState({isLoaded: true});
    const userHasAuthenticated = await AsyncStorage.getItem('userHasAuthenticated');
    if (userHasAuthenticated === 'true' && this.state.isAuthenticated) this.checkBackend();
    this.loadStatus();
  }

  checkBackend = async () => {
    const checkProtected = await Api.Status.checkProtected();
    if (checkProtected.error) alert(checkProtected.message);
    else console.log(checkProtected);
  }

  loadStatus = async () => {
    const status = await Api.Status.get();
    if (status.error) alert(status.message);
    else {
      console.log(status);
      var data = {};
      var dataWithGraph = {};
      var dataWithoutGraphObject = {};
      var dataWithoutGraphArray = [];
      status.data.forEach(x => {
        if (!data[x.table]) data[x.table] = {DataPoint: [], DataPointMinute: [], DataPointHour: [], DataPointDay: []}
        if (x.type === 'DataPoint') data[x.table].DataPoint.push({name: x.name, value: x.value})
        else if (x.type === 'DataPointMinute') data[x.table].DataPointMinute.push({name: x.name, value: x.value})
        else if (x.type === 'DataPointHour') data[x.table].DataPointHour.push({name: x.name, value: x.value})
        else if (x.type === 'DataPointDay') data[x.table].DataPointDay.push({name: x.name, value: x.value})
      })

      Object.keys(data).map(x => data[x].DataPointMinute.length + data[x].DataPointHour.length + data[x].DataPointDay.length >0 ? dataWithGraph[x] = data[x] : dataWithoutGraphObject[x] = data[x] )
      var temp = {}
      Object.keys(dataWithoutGraphObject).forEach((x,i) => {
        temp[x] = dataWithoutGraphObject[x];
        if ((i + 1) % 4 === 0){
          dataWithoutGraphArray.push(temp);
          temp = {}
        }
      })
      if (Object.keys(temp).length > 0) dataWithoutGraphArray.push(temp);
      this.setState({data: data, dataWithGraph: dataWithGraph, dataWithoutGraph: dataWithoutGraphArray})


      console.log(this.state.dataWithoutGraph);
    }
  }

  render(){
    return (
      <div className="container page_container">
        { !this.state.isLoaded ? <Loader></Loader> : <>
          <div>
            <Container fluid>
              <table border="1" width="100%"><tbody>
                {this.state.dataWithoutGraph.map(z => <tr>
                  {Object.keys(z).map(x => 
                    <td width="25%"><div><b>{x}</b></div>{z[x].DataPoint.map(y=> <div>{y.name}{y.value !== null ? ':' + y.value : ''}</div>)}</td>
                  )}
                </tr>)}
                {Object.keys(this.state.dataWithGraph).map(x => <tr>
                  <td width="25%"><div><b>{x}</b></div>{this.state.dataWithGraph[x].DataPoint.map(y=> <div>{y.name}{y.value !== null ? ':' + y.value : ''}</div>)}</td>
                  <td width="25%"><Line 
                    options={{plugins: {title: {display: true, text: 'Po danu'}, legend: {display: false}}}}
                    data={{datasets: [{borderColor: 'rgb(0, 0, 0)', data: this.state.dataWithGraph[x].DataPointDay.map(z=>{return {x: z.name.split(' ')[0], y: z.value}})}]}}/>
                  </td>
                  <td width="25%"><Line 
                    options={{plugins: {title: {display: true, text: 'Po satu'}, legend: {display: false}}}}
                    data={{datasets: [{borderColor: 'rgb(0, 0, 0)', data: this.state.dataWithGraph[x].DataPointHour.map(z=>{return {x: z.name.split(':')[0], y: z.value}})}]}}/>
                  </td>
                  <td width="25%"><Line 
                    options={{plugins: {title: {display: true, text: 'Po minuti'}, legend: {display: false}}}}
                    data={{datasets: [{borderColor: 'rgb(0, 0, 0)', data: this.state.dataWithGraph[x].DataPointMinute.map(z=>{return {x: z.name.split('-')[2], y: z.value}})}]}}/>
                  </td>
                </tr>)}
              </tbody></table>
            </Container>
          </div>
        </> }
      </div>
    );  
  }

}

export default Status;